import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface PermissionAlert {
  url: string;
  permissions: number[];
}
@Injectable({
  providedIn: 'root',
})
export class SitePermissionAlertService {
  private _alert$ = new BehaviorSubject<PermissionAlert | undefined>(undefined);
  alert$ = this._alert$.asObservable();
  constructor() {}
  sendAlert(permissionAlert: PermissionAlert | undefined) {
    this._alert$.next(permissionAlert);
  }
}
