import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function AtLeastOneValidator(keys: string[]): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const { controls } = group as FormGroup;
    return keys.some((key) => {
      return (
        controls[key] &&
        !!controls[key].value &&
        (!Array.isArray(controls[key].value) || controls[key].value.length > 0)
      );
    })
      ? null
      : { atLeastOne: 'error' };
  };
}
