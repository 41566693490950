import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageAuthPipe } from '../image-auth/image-auth.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, ImageAuthPipe],
  selector: 'sol-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() fileId?: number | null;
  @Input() userId?: number;
  @Input() size: number = 128;
  constructor() {}
}
