import {
  Directive,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[formFocus]',
})
export class FormFocusDirective implements AfterViewInit {
  @Input('initialFocus')
  public initialFocus?: boolean = true;

  constructor(
    private element: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}
  public focus() {
    const input = this.element.nativeElement;
    if (input) {
      input.addEventListener('focus', this.cdr.detectChanges());
      input.focus();
      setTimeout(() => input.focus(), 1);
    }
  }

  ngAfterViewInit() {
    if (this.initialFocus) {
      this.focus();
    }
  }
}
