import { Directive, HostListener, Input } from '@angular/core';
import { FileItem, FileService } from '../../services/file.service';

@Directive({
  standalone: true,
  selector: '[solDownloadFile]',
})
export class DownloadFileDirective {
  constructor(private readonly fileService: FileService) {}

  @Input('solDownloadFile')
  public file?: FileItem;
  @Input('path') path?: string;
  @HostListener('click')
  public onClick() {
    if (this.file && this.path) {
      this.fileService.downloadFile(this.file, this.path);
    }
  }
}
