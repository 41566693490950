import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserPermissionsStoreService } from '../../services/user-permissions-store.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'sol-permission-missing',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './permission-missing.component.html',
  styleUrl: './permission-missing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionMissingComponent {
  @Input() set permission(permission: number | number[]) {
    if (typeof permission == 'number') {
      this.singlePermission = permission;
    } else {
      this.permissions = permission;
    }
  }
  singlePermission?: number;
  permissions?: number[];
  EP = this.userPermissionStore.Permissions;
  constructor(private userPermissionStore: UserPermissionsStoreService) {}
}
