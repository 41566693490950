import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../Tokens/tokens';
import { CommonSolutionConfig } from '../common-solution-config';
export interface FileType {
  fileExtension: string;
  type: FileTypes;
}
export enum FileTypes {
  IMAGE,
  PDF,
  VIDEO,
  AUDIO,
  TEXT,
  UNKNOWN,
}
export interface FileItem {
  id: number;
  name: string;
}
@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private http: HttpClient,
    @Inject(COMMON_SOLUTION_DEFAULT_OPTIONS)
    private config: CommonSolutionConfig,
  ) {}

  downloadFile(file: FileItem, path: string) {
    if (file) {
      const downloadUrl = this.config.apiBaseUrl + path + file.id;
      if (downloadUrl) {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${this.config.getToken()}`,
        });
        // Download the document as a blob
        this.http
          .get(downloadUrl, {
            headers: headers,
            responseType: 'blob',
            observe: 'response',
          })
          .subscribe((response) => {
            if (response?.body) {
              // Create a URL for the blob
              const url = URL.createObjectURL(response.body);

              // Create an anchor element to "point" to it
              const anchor = document.createElement('a');
              anchor.href = url;

              // Get the suggested filename for the file from the response headers
              anchor.download =
                file.name ??
                (this.getFilenameFromHeaders(response.headers) || 'file');

              // Simulate a click on our anchor element
              anchor.click();
              // Discard the object data
              URL.revokeObjectURL(url);
            }
          });
      }
    }
  }
  openFileInNewTab(file: FileItem, path: string) {
    if (file) {
      const downloadUrl = this.config.apiBaseUrl + path + file.id;
      if (downloadUrl) {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${this.config.getToken()}`,
        });
        // Download the document as a blob
        this.http
          .get(downloadUrl, {
            headers: headers,
            responseType: 'blob',
            observe: 'response',
          })
          .subscribe((response) => {
            if (response?.body) {
              // Create a URL for the blob
              const url = URL.createObjectURL(response.body);

              // Create an anchor element to "point" to it
              const anchor = document.createElement('a');
              anchor.href = url;

              // Get the suggested filename for the file from the response headers
              anchor.download =
                file.name ??
                (this.getFilenameFromHeaders(response.headers) || 'file');

              // Open in new Tab
              window.open(url, '_blank');
              // Discard the object data
              URL.revokeObjectURL(url);
            }
          });
      }
    }
  }

  private getFilenameFromHeaders(headers: HttpHeaders) {
    // The content-disposition header should include a suggested filename for the file
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
      return null;
    }

    /* StackOverflow is full of RegEx-es for parsing the content-disposition header,
     * but that's overkill for my purposes, since I have a known back-end with
     * predictable behaviour. I can afford to assume that the content-disposition
     * header looks like the example in the docs
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
     *
     * In other words, it'll be something like this:
     *    Content-Disposition: attachment; filename="filename.ext"
     *
     * I probably should allow for single and double quotes (or no quotes) around
     * the filename. I don't need to worry about character-encoding since all of
     * the filenames I generate on the server side should be vanilla ASCII.
     */

    const leadIn = 'filename=';
    const start = contentDisposition.search(leadIn);
    if (start < 0) {
      return null;
    }

    // Get the 'value' after the filename= part (which may be enclosed in quotes)
    const value = contentDisposition.substring(start + leadIn.length).trim();
    if (value.length === 0) {
      return null;
    }

    // If it's not quoted, we can return the whole thing
    const firstCharacter = value[0];
    if (firstCharacter !== '"' && firstCharacter !== "'") {
      return value;
    }

    // If it's quoted, it must have a matching end-quote
    if (value.length < 2) {
      return null;
    }

    // The end-quote must match the opening quote
    const lastCharacter = value[value.length - 1];
    if (lastCharacter !== firstCharacter) {
      return null;
    }

    // Return the content of the quotes
    return value.substring(1, value.length - 1);
  }
  public static fileTypes: FileType[] = [
    { fileExtension: '.bm', type: FileTypes.IMAGE },
    { fileExtension: '.bmp', type: FileTypes.IMAGE },
    { fileExtension: '.gif', type: FileTypes.IMAGE },
    { fileExtension: '.jfif', type: FileTypes.IMAGE },
    { fileExtension: '.jfif-tbnl', type: FileTypes.IMAGE },
    { fileExtension: '.jpe', type: FileTypes.IMAGE },
    { fileExtension: '.jpeg', type: FileTypes.IMAGE },
    { fileExtension: '.jpg', type: FileTypes.IMAGE },
    { fileExtension: '.jps', type: FileTypes.IMAGE },
    { fileExtension: '.jut', type: FileTypes.IMAGE },
    { fileExtension: '.png', type: FileTypes.IMAGE },
    { fileExtension: '.tif', type: FileTypes.IMAGE },
    { fileExtension: '.tiff', type: FileTypes.IMAGE },
    { fileExtension: '.pdf', type: FileTypes.PDF },
    { fileExtension: '.txt', type: FileTypes.TEXT },
    { fileExtension: '.text', type: FileTypes.TEXT },
    { fileExtension: '.text', type: FileTypes.TEXT },
    { fileExtension: '.log', type: FileTypes.TEXT },
    { fileExtension: '.avi', type: FileTypes.VIDEO },
    { fileExtension: '.mp4', type: FileTypes.VIDEO },
    { fileExtension: '.mjpg', type: FileTypes.VIDEO },
    { fileExtension: '.mov', type: FileTypes.VIDEO },
    { fileExtension: '.moov', type: FileTypes.VIDEO },
    { fileExtension: '.mpe', type: FileTypes.VIDEO },
    { fileExtension: '.mpe', type: FileTypes.VIDEO },
    { fileExtension: '.mpg', type: FileTypes.VIDEO },
    { fileExtension: '.mpeg', type: FileTypes.VIDEO },
    { fileExtension: '.qt', type: FileTypes.VIDEO },
    { fileExtension: '.movie', type: FileTypes.VIDEO },
    { fileExtension: '.mp3', type: FileTypes.AUDIO },
    { fileExtension: '.wav', type: FileTypes.AUDIO },
    { fileExtension: '.mod', type: FileTypes.AUDIO },
  ];
}
