import { AbstractControl, ControlContainer } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

/**
 * Finds a form control explicitly or by name from the ControlContainer.
 *
 * @param control An existing form control, as passed with the formControl directive
 * @param controlName An form control name, as passed with the formControlName directive
 * @param controlContainer The Directive’s ControlContainer
 */
export const findFormControl = (
  control?: AbstractControl,
  controlName?: string,
  controlContainer?: ControlContainer
): AbstractControl => {
  if (control) {
    return control;
  }
  if (!controlName) {
    throw new Error('getFormControl: control or control name must be given');
  }
  if (!(controlContainer && controlContainer.control)) {
    throw new Error(
      'getFormControl: control name was given but parent control not found'
    );
  }
  const controlFromName = controlContainer.control.get(controlName);
  if (!controlFromName) {
    throw new Error(`getFormControl: control '${controlName}' not found`);
  }
  return controlFromName;
};


/**
 * Extract arguments of function
 */
export type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

/**
 * Creates an object like O. Optionally provide minimum set of properties P which the objects must share to conform
 */
type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>;

/**
 * Extract a touched changed observable from an abstract control
 * @param control AbstractControl like object with markAsTouched method
 */
export const extractTouchedChanges = (
  control: ObjectLike<AbstractControl, 'markAsTouched' | 'markAsUntouched'>,
): Observable<'TOUCHED' | 'UNTOUCHED'> => {
  const prevMarkAsTouched = control.markAsTouched.bind(control);
  const prevMarkAsUntouched = control.markAsUntouched.bind(control);

  const touchedChanges$ = new Subject<'TOUCHED' | 'UNTOUCHED'>();

  function nextMarkAsTouched(
    ...args: ArgumentsType<AbstractControl['markAsTouched']>
  ) {
    prevMarkAsTouched(...args);
    touchedChanges$.next('TOUCHED');
  }

  function nextMarkAsUntouched(
    ...args: ArgumentsType<AbstractControl['markAsUntouched']>
  ) {
    prevMarkAsUntouched(...args);
    touchedChanges$.next('UNTOUCHED');
  }

  control.markAsTouched = nextMarkAsTouched;
  control.markAsUntouched = nextMarkAsUntouched;

  return touchedChanges$;
};
