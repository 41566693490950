<div class="e-float-input e-input-group">
  <input class="e-input" type="search" [formControl]="searchControl" />
  <span class="e-float-line"></span> <label class="e-float-text">Suche</label>
</div>
<div *ngFor="let config of selectorConfigs">
  <ng-container *ifPermission="config.permission">
    <sol-selector
      [withSearch]="false"
      [selectorConfig]="config"
      [searchValue]="this.searchValue$ | async"
      (itemSelect)="onItemSelect($event, config)"
    ></sol-selector>
  </ng-container>
</div>
