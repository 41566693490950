import {
  BehaviorSubject,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  ReplaySubject,
  shareReplay,
  combineLatest,
  take,
  toArray,
  catchError,
  EMPTY,
  throwError,
  mergeMap,
} from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';
import { BaseService } from './base.service';
import { ItemState } from './item-state';
import { IFilterDto } from '../FilterTypeDtos/i-filter-dto';
import { AuthenticationService } from './authentication-service';
import { UserPermissionsStoreService } from './user-permissions-store.service';
import { PredicateModel } from '@syncfusion/ej2-angular-grids';
import { TranslocoService } from '@ngneat/transloco';
import { showErrorToast } from '../util';
export interface Item {
  id: number;
}
@Directive()
export abstract class BaseStoreService<TGetDto extends Item, TPutDto, TPostDto>
  implements OnDestroy
{
  protected onDestroy$ = new Subject();
  protected _state$ = new BehaviorSubject<ItemState<TGetDto>>({
    items: [],
    loading: false,
    success: false,
  });
  state$ = this._state$.asObservable();
  items$: Observable<TGetDto[]> = this._state$.pipe(
    tap(() => {
      if (this.firstLoad) {
        this.firstLoad = false;
        this.refresh();
      }
    }),
    map((s) => s.items),
  );
  refresh$ = new Subject();

  private _filter$ = new ReplaySubject<IFilterDto | undefined>(10, 300000);
  protected filter$ = this._filter$.pipe(shareReplay(1));
  hasPermission$: Observable<boolean>;
  firstLoad = true;
  constructor(
    private authenticationService: AuthenticationService,
    protected baseService: BaseService<TGetDto, TPutDto, TPostDto>,
    private userPermissionStore: UserPermissionsStoreService,
    readPermission: number | undefined,
    private translocoService: TranslocoService,
  ) {
    this.hasPermission$ = readPermission
      ? this.userPermissionStore.checkPermissionAsync(readPermission)
      : of(true);
    this._filter$.next(undefined);
    this.loadItems();

    // this.refresh();
  }
  refresh() {
    this.refresh$.next(true);
  }
  refreshItem(id: number) {
    this.baseService
      .getById(id)
      .pipe(tap((item) => this.addOrUpdateItemGet(item)))
      .subscribe();
  }
  private loadItems() {
    combineLatest([
      this.authenticationService.isAuthenticated$,
      this.refresh$,
      this.filter$,
      this.hasPermission$,
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        mergeMap(([isAuth, reload, filter, hasPermission]) => {
          this.setLoading(true);
          if (isAuth && hasPermission) {
            return this.baseService.getAll(
              filter,
              filter?.limit,
              filter?.operator,
            );
          }
          return of([]);
        }),
      )
      .subscribe((t) => this.setItems(t));
  }
  getSingle(id: number, withErrorToast = true): Observable<TGetDto> {
    const items = this.getItems();
    const item = items.find((y) => y.id == id);
    if (item) {
      return of(item);
    } else {
      return this.baseService.getById(id).pipe(
        tap((item) => this.addOrUpdateItemGet(item)),
        catchError((err) => {
          if (err.status == 404 && withErrorToast) {
            this.translocoService
              .selectTranslate('Entity.' + this.baseService.path)
              .subscribe((enitityName) =>
                showErrorToast(
                  enitityName + ' mit ID ' + id + ' nicht gefunden',
                ),
              );
          }
          return throwError(() => err);
        }),
      );
    }
  }

  create(item: TPostDto): Observable<TGetDto> {
    return this.baseService.create(item).pipe(tap((dt) => this.addItem(dt)));
  }
  update(id: number, item: TPutDto) {
    return this.baseService
      .update(id, item as unknown as TPutDto)
      .pipe(
        tap((o) =>
          this.addOrUpdateItemGet({ ...item, id } as unknown as TGetDto),
        ),
      );
  }

  delete(id: number) {
    return this.baseService.delete(id).pipe(tap((o) => this.removeItem(id)));
  }
  private getItems() {
    return this._state$.value.items;
  }
  setLoading(loading: boolean) {
    this._state$.next({
      ...this._state$.value,
      loading: loading,
      success: !loading,
    });
  }
  setSuccess(success: boolean) {
    this._state$.next({
      ...this._state$.value,
      success: success,
    });
  }
  setItems(items: TGetDto[]) {
    if (items.length > 0) {
      const mergedItems = [
        ...items,
        ...this.getItems().filter((t) => !items.find((ts) => ts.id == t.id)),
      ];
      this._state$.next({
        items: mergedItems,
        loading: false,
        success: true,
      });
    }
  }
  addOrUpdateItemGet(item: TGetDto) {
    if (!this.getItems().find((l) => l.id == item.id)) {
      this.addItem(item);
    } else {
      this.updateItemGet(item);
    }
  }
  private updateItemGet(item: TGetDto) {
    this.setItems(
      this.getItems().map((t) => (t.id == item.id ? { ...t, ...item } : t)),
    );
  }
  private addItem(item: TGetDto) {
    this._state$.next({
      items: [...this.getItems(), item],
      loading: false,
      success: true,
    });
  }
  removeItem(id: number) {
    this.setItems(this.getItems().filter((a) => a.id != id));
  }
  private completionTrigger$ = new Subject<void>();
  setSearchFilter(filterDto: IFilterDto | undefined) {
    this._filter$
      .pipe(
        takeUntil(this.onDestroy$), // Ensure we can clean up to prevent memory leaks
        takeUntil(this.completionTrigger$), // Listen for the signal to complete the collection of emissions
        toArray(), // Collect all emissions into an array
        take(1), // Ensure this pipeline is only executed once
      )
      .subscribe((values: (IFilterDto | undefined)[]) => {
        // Process the array of values to determine if all are 'new'
        const allAreNew = values.every((val) => {
          if (val === undefined && filterDto === undefined) {
            return false;
          } else if (val === undefined && filterDto !== undefined) {
            return true;
          } else if (val && JSON.stringify(val) !== JSON.stringify(filterDto)) {
            return true;
          } else {
            return false;
          }
        });

        if (allAreNew) {
          this._filter$.next(filterDto);
        }
      });

    // Trigger the completion of the ReplaySubject collection
    this.completionTrigger$.next();
  }

  getFilterPredicateModel(
    filterModels: PredicateModel[],
  ): IFilterDto | undefined {
    throw new Error('Method not implemented.');
    return {};
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
