import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  importProvidersFrom,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { NgxCommonSolutionModule } from 'ngx-common-solution';
import { AUTHENTICATION_DEFAULT_OPTIONS } from './Tokens/tokens';
import { AuthenticationConfig } from './authentication-config';
import { authInterceptor } from './authentication.interceptor';
import { NgxAuthenticationRoutingModule } from './ngx-authentication-routing.module';
import { getAuthToken } from './tokens';
import { TranslocoRootModule } from './transloco-root.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxAuthenticationRoutingModule,
    TranslocoRootModule,
    NgxCommonSolutionModule,
    // OAuthModule.forRoot({ resourceServer: { sendAccessToken: false } }),
  ],
  exports: [],
  providers: [],
})
export class NgxAuthenticationModule {
  static forRoot(
    config: AuthenticationConfig,
  ): ModuleWithProviders<NgxAuthenticationModule> {
    return {
      ngModule: NgxAuthenticationModule,
      providers: [
        {
          provide: AUTHENTICATION_DEFAULT_OPTIONS,
          useValue: config,
        },
        provideHttpClient(
          withInterceptors([authInterceptor]),
          withInterceptorsFromDi(),
        ),
        provideOAuthClient(),
        importProvidersFrom(
          JwtModule.forRoot({
            config: {
              throwNoTokenError: false,
              tokenGetter: getAuthToken,
              allowedDomains: config.allowedDomains,
              disallowedRoutes: [],
            },
          }),
        ),
      ],
    };
  }
}
