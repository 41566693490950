<div>
  <div class="mb-2 flex w-full gap-2">
    <!-- <button
      ejs-button
      type="button"
      class="icon-button"
      (click)="retryErrors()"
    >
      <div>
        <i class="fa-regular fa-rotate-right"></i>
        Erneut laden
      </div>
    </button> -->
    <div class="m-auto w-max">Fehler: {{ (errors$ | async)?.length }}</div>
    <button ejs-button class="small-button" (click)="toggleExpanded()">
      <div>
        <i *ngIf="expanded" class="fa-regular fa-chevron-up"></i>
        <i *ngIf="!expanded" class="fa-regular fa-chevron-down"></i>
      </div>
    </button>
    <button ejs-button class="small-button" (click)="dismissAll()">
      <div>
        <i class="fa-regular fa-xmark"></i>
      </div>
    </button>
  </div>
  <div *ngIf="expanded">
    <div
      *ngFor="let error of errors$ | async"
      class="flex justify-between border-t-2"
    >
      <div>
        <div>{{ error.title }}</div>
        <div>{{ error.status }}</div>
        <div class="flex">
          <button
            ejs-button
            class="small-button"
            (click)="onStacktraceClick(error)"
          >
            <i class="fa-regular fa-arrow-up-right-from-square"></i>
          </button>
          <button
            ejs-button
            class="small-button"
            [cdkCopyToClipboard]="
              error.status + '\r' + error.title + '\r' + error.details
            "
          >
            <i class="fa-regular fa-copy pr-1"></i>Copy
          </button>
        </div>
      </div>
      <button ejs-button class="small-button" (click)="dismissError(error)">
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
  </div>
</div>
