<button
  ejs-button
  class="icon-button"
  type="button"
  appDnd
  (fileDropped)="onFileDropped($event)"
  (click)="fileUpload.click()"
  [disabled]="(uploadProgress$ | async) != 0"
>
  <i class="fa-solid fa-upload" *ngIf="!(uploadProgress$ | async)"></i>
  <div class="h-6 w-6" *ngIf="uploadProgress$ | async as progress">
    <div
      *ngIf="progress > 0"
      class="absolute bottom-0 left-0 top-0"
      style="content:'\A';width: {{
        progress
      }}%; background:#dedede;color: black!important;"
    >
      <div class="absolute left-2 top-0">
        {{ progress + "%" }}
      </div>
    </div>
    <div class="spinner-wrapper w-6" *ngIf="progress == -1">
      <sol-spinner></sol-spinner>
    </div>
  </div>
</button>
<input
  style="display: none"
  type="file"
  class="file-input"
  [accept]="requiredFileType"
  (change)="onFileSelected($event)"
  #fileUpload
/>
