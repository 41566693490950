import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  standalone: true,
  imports: [IconButtonComponent],
  selector: 'sol-icon-button-small',
  templateUrl: './icon-button-small.component.html',
  styleUrls: ['./icon-button-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonSmallComponent {
  @Input() text: string = '';
  @Input() icon: string = '';
}
