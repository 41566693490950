import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FileItem,
  FileService,
  FileTypes,
} from '../../../services/file.service';
import {
  ButtonArgs,
  ConfirmDialogArgs,
  DialogModule,
  DialogUtility,
} from '@syncfusion/ej2-angular-popups';
import { ImageDisplayComponent } from '../image-display/image-display.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, DialogModule, ImageDisplayComponent],
  selector: 'sol-image-edit-wrapper',
  templateUrl: './image-edit-wrapper.component.html',
  styleUrls: ['./image-edit-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditWrapperComponent implements OnInit {
  FileTypes = FileTypes;
  @Input() public file?: FileItem;
  @Input() alt = '';
  @Input() path = '';
  @Input() thumbnail = true;
  @Input() size = 128;
  @Output() fileDeleting = new EventEmitter<FileItem>();
  @Output() fileUploading = new EventEmitter<File>();
  src: string = 'assets/img/placeholder-image.png';
  matIconText?: string;
  fileType?: FileTypes;
  constructor(
    @Optional() public dialogRef: MatDialogRef<ImageEditWrapperComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fileService: FileService,
  ) {}
  ngOnInit(): void {
    if (this.data) {
      this.file = this.data.file;
      this.path = this.data.path;
      this.thumbnail = this.data.thumbnail;
      this.alt = this.data.alt;
    }
    if (this.file) {
      const extension = '.' + this.file.name.split('.').pop();
      if (extension) {
        const fileType = FileService.fileTypes.find(
          (x) => x.fileExtension === extension.toLowerCase(),
        );
        if (fileType) {
          this.fileType = fileType.type;
        } else {
          this.fileType = FileTypes.UNKNOWN;
        }
      }
    }
  }

  onImageClick() {
    if (this.thumbnail && this.fileType == FileTypes.IMAGE) {
      const dialogRef = this.dialog.open(ImageEditWrapperComponent, {
        data: { file: this.file, path: this.path },
      });
      dialogRef.componentInstance.fileDeleting.subscribe((file) => {
        if (file) {
          this.fileDeleting.emit(this.file);
          dialogRef.close();
        }
      });
    }
    if (
      this.file &&
      this.path &&
      this.fileType !== undefined &&
      this.fileType !== FileTypes.IMAGE
    ) {
      if (this.fileType == FileTypes.PDF) {
        this.fileService.openFileInNewTab(this.file, this.path);
      } else this.fileService.downloadFile(this.file, this.path);
    }
  }
  onDeleteClick(e: any) {
    if (this.file) this.confirmDelete(this.file);
    e.stopPropagation();
  }
  onConfirmDeleteClick() {
    this.fileDeleting.emit(this.file);
  }
  confirmDelete(file: FileItem) {
    DialogUtility.confirm({
      content: 'Möchten Sie das Bild endgültig löschen?',
      okButton: { click: this.onConfirmDeleteClick.bind(this) } as ButtonArgs,
    } as ConfirmDialogArgs);
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.sendFile(file);
    }
  }
  sendFile(file: File) {
    this.fileUploading.emit(file);
  }
}
