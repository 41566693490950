import { Injectable } from '@angular/core';
import {
  catchError,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  timeout,
} from 'rxjs';
import { LoginResultDto } from '../../dto/login-result-dto';
import { LoginStatus } from '../../dto/login-status';
import { RefreshTokenRequestDto } from '../../dto/refresh-token-request-dto';
import { AuthenticationService } from '../services/authentication.service';
import { AuthenticationStatusService } from './authentication-status.service';
import { TokenStoreService } from './token-store.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService {
  refreshTokenInProgress = false;
  private _tokenRefreshed$ = new Subject<boolean>();
  tokenRefreshed$ = this._tokenRefreshed$.asObservable();
  constructor(
    private authenticationService: AuthenticationService,
    private tokenStore: TokenStoreService,
    private authenticationStatusService: AuthenticationStatusService,
  ) {}

  refreshToken(): Observable<boolean> {
    if (this.refreshTokenInProgress) {
      return new Observable<boolean>((observer) => {
        this.tokenRefreshed$.subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.sendRefreshToken().pipe(
        timeout(5000),
        tap((res) => {
          this.refreshTokenInProgress = false;
          this._tokenRefreshed$.next(res.status == LoginStatus.Success);
        }),
        map((res) => res.status == LoginStatus.Success),
        tap((res) => this.authenticationStatusService.setAuthenticated(res)),
        catchError((error) => {
          this.refreshTokenInProgress = false;
          return of(false);
        }),
      );
    }
  }
  private sendRefreshToken(): Observable<LoginResultDto> {
    var accessToken = this.tokenStore.getToken();
    var refreshToken = this.tokenStore.getRefreshToken();
    return of({
      accessToken: accessToken,
      refreshToken: refreshToken,
    }).pipe(
      switchMap((ref) => {
        if (ref.accessToken != null && ref.refreshToken != null) {
          return this.authenticationService.refreshToken(
            ref as RefreshTokenRequestDto,
          );
        } else {
          return of({
            status: LoginStatus.Failed,
          } as LoginResultDto);
        }
      }),
      tap((res) => {
        if (res.status == LoginStatus.Success && res.token) {
          this.tokenStore.saveLoginDataOnSuccess(res);
          console.log('Token erfolgreich erneuert');
        }
      }),
    );
  }
}
