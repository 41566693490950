<ejs-dialog
  [showCloseIcon]="true"
  class="img-container mx-auto p-0"
  [ngClass]="{ placeholder: thumbnail }"
>
  <sol-image-display
    class="content-wrapper"
    [file]="file"
    [thumbnail]="thumbnail"
    [path]="path"
    [size]="512"
    (click)="onImageClick()"
  >
  </sol-image-display>
  <div
    class="hide overlay"
    [ngClass]="{ 'no-overlay': thumbnail }"
    (click)="onImageClick()"
  >
    <div class="overlay-items">
      <div
        class="overlay-item"
        style="background-color: red"
        (click)="onDeleteClick($event)"
      >
        <i style="vertical-align: middle" class="fa-solid fa-trash-can"></i>
      </div>
      <div
        class="overlay-item"
        style="background-color: red"
        (click)="uploadFile.click(); $event.stopPropagation()"
      >
        <input
          #uploadFile
          type="file"
          class="file-input"
          accept="image/*"
          (change)="onFileSelected($event)"
          #fileUpload
        />
        <i style="vertical-align: middle" class="fa-solid fa-upload"></i>
      </div>
    </div>
  </div>
</ejs-dialog>
