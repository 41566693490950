import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  fromEvent,
  of,
  shareReplay,
  takeUntil,
} from 'rxjs';
import { Item } from '../services/base-store.service';
import {
  Column,
  ColumnModel,
  FilterEventArgs,
  FilterMenuRendererArgs,
  FilterSearchBeginEventArgs,
  FilterSettingsModel,
  GridComponent,
} from '@syncfusion/ej2-angular-grids';
import { createElement } from '@syncfusion/ej2-base';
import {
  DropDownList,
  SelectEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import { DataUtil, DataManager } from '@syncfusion/ej2-data';
import { DatePicker, DateRangePicker } from '@syncfusion/ej2-angular-calendars';

export interface ColumnConfiguration<T extends Item> {
  items: T[];
  columnName: string;
  withBlank?: boolean;
  foreignKeyValue?: string;
}
export interface DateColumnConfiguration {
  columnName: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  customFilter: boolean;
  rangePicker?: DateRangePicker;
  datePicker?: DatePicker;
}
export interface SetColumnConfiguration {
  columnName: string;
  componentInstance?: ISearchComponent;
}
interface ISearchComponent {
  searchCompleted: EventEmitter<any | undefined>;
}
@Injectable({
  providedIn: 'root',
})
export class SfGridFilterService implements OnDestroy {
  private onDestroy$ = new Subject();
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this._isLoading$.pipe(shareReplay(1));

  menuFilterSettings: FilterSettingsModel = {
    type: 'Menu',
    operators: {
      stringOperator: [
        { value: 'contains', text: 'Enthält' },
        { value: 'startsWith', text: 'Startet mit' },
        { value: 'endsWith', text: 'Endet mit' },
        { value: 'equal', text: 'Gleich' },
        { value: 'notEqual', text: 'Nicht gleich' },
      ],
      numberOperator: [
        { value: 'equal', text: 'Gleich' },
        { value: 'notEqual', text: 'Nicht gleich' },
        { value: 'greaterThan', text: 'Größer als' },
        { value: 'lessThan', text: 'Kleiner als' },
      ],
      dateTimeOperator: [
        { value: 'lessThan', text: 'Vor' },
        { value: 'greaterThan', text: 'Nach' },
        { value: 'equal', text: 'Gleich' },
        // { value: 'notEqual', text: 'Nicht gleich' },
      ],
      dateOperator: [
        { value: 'lessThan', text: 'Vor' },
        { value: 'greaterThan', text: 'Nach' },
        { value: 'equal', text: 'Gleich' },
        // { value: 'notEqual', text: 'Nicht gleich' },
        // { value: 'null', text: 'Null' },
        // { value: 'notNull', text: 'Not Null' },
        { value: 'inRange', text: 'Zwischen' },
      ],
      booleanOperator: [
        { value: 'equal', text: 'Gleich' },
        { value: 'notEqual', text: 'Nicht gleich' },
      ],
    },
  };
  columnConfigurations: ColumnConfiguration<any>[] = [];
  setColumnConfigurations: SetColumnConfiguration[] = [];
  dateColumnConfigurations: DateColumnConfiguration[] = [];
  lastDate?: Date;
  grid?: GridComponent;
  created = false;
  private storageKey?: string;
  private initialFilter: { field: string; operator: string; value: any }[] = [];
  hideSearchForConfig: ColumnConfiguration<any>[] = [];
  constructor() {
    (DataUtil.fnOperators as any).inRange = (
      actual: Date,
      expected: Date,
      ignoreCase: any,
      ignoreAccent: any,
    ) => {
      if (this.lastDate && this.lastDate < expected) {
        this.lastDate = expected;
        const val = actual && expected && actual <= expected;
        return val;
      } else if (!this.lastDate || this.lastDate >= expected) {
        this.lastDate = expected;
        const val = actual && expected && actual >= expected;
        return val;
      }
      this.lastDate = expected;
      return false;
    };
    (DataUtil.operatorSymbols as any)['inrange'] = 'inRange';

    fromEvent(window, 'resize')
      .pipe(takeUntil(this.onDestroy$), debounceTime(200))
      .subscribe(() => this.onresize());
  }

  onresize() {
    if (this.grid) {
      var rowHeight = this.grid.getRowHeight(); //height of the each row
      var gridHeight = Number(
        window.innerHeight - (this.grid.toolbarModule ? 161 : 118),
      ); //grid height
      var pageSize = Number(this.grid.pageSettings.pageSize) + 10; //initial page size
      var pageResize = (gridHeight - pageSize * rowHeight) / rowHeight;
      var newPageSize = pageSize + Math.round(pageResize) - 1;
      if (newPageSize < 10) {
        newPageSize = 10;
      }
      this.grid.pageSettings.pageSize = newPageSize;
    }
  }
  setLoading(loading: boolean) {
    this._isLoading$.next(loading);
  }
  addColumnConfiguration<T extends Item>(columnConfig: ColumnConfiguration<T>) {
    const oldConfigIndex = this.columnConfigurations.findIndex(
      (c) => c.columnName == columnConfig.columnName,
    );
    const newColumnConfig = {
      ...columnConfig,
      items: columnConfig.items.map((item) => {
        let i: any = { ...item };
        i[columnConfig.columnName] = item.id;
        return i;
      }),
    };
    if (newColumnConfig.withBlank) {
      let i: any = {};
      i[columnConfig.columnName] = null;
      newColumnConfig.items.push(i);
    }
    if (oldConfigIndex == -1) {
      this.columnConfigurations.push(newColumnConfig);
    } else {
      this.columnConfigurations[oldConfigIndex] = newColumnConfig;
    }

    if (newColumnConfig.foreignKeyValue) {
      if (this.grid) {
        const col = (this.grid.columns as ColumnModel[]).find(
          (c) => c.field == newColumnConfig.columnName,
        );
        if (col) {
          col.dataSource = new DataManager(newColumnConfig.items);
          col.foreignKeyField = 'id';
          col.foreignKeyValue = newColumnConfig.foreignKeyValue;
        }
      } else {
        this.hideSearchForConfig.push(newColumnConfig);
      }
    }
  }
  addDateColumnConfiguration(columnConfig: DateColumnConfiguration) {
    const oldConfigIndex = this.dateColumnConfigurations.findIndex(
      (c) => c.columnName == columnConfig.columnName,
    );
    if (oldConfigIndex == -1) {
      this.dateColumnConfigurations.push(columnConfig);
    } else {
      this.dateColumnConfigurations[oldConfigIndex] = columnConfig;
    }
  }
  onCreated(grid: GridComponent, storageKey: string) {
    this.storageKey = storageKey;
    this.grid = grid;
    grid.filterSettings = this.menuFilterSettings;
    let value: string = window.localStorage.getItem(storageKey) as string;
    let state = JSON.parse(value);
    if (state) {
      const columns = !state.columns
        ? this.grid.columns
        : this.grid.columns
            .sort((a: ColumnModel, b: ColumnModel) => {
              if (!a.field) return -1;
              if (!b.field) return 1;
              const indexA = state.columns[a.field];
              const indexB = state.columns[b.field];
              if (indexA > indexB) {
                return 1;
              }
              if (indexA < indexB) {
                return -1;
              }
              return 0;
            })
            .map((c: ColumnModel) => {
              return {
                ...c,
                visible: c.field
                  ? state.columns[c.field]?.visible ?? true
                  : c.visible,
              };
            });
      state = {
        ...state,
        columns: columns,
      };
      this.grid.setProperties(state);
    }
    this.applyInitialFilter();
    this.onresize();
    this.created = true;
  }
  setInitialFilter(field: string, operator: string, value: any) {
    this.initialFilter = [...this.initialFilter, { field, operator, value }];
    if (this.created && this.grid) {
      this.applyInitialFilter();
    }
  }
  applyInitialFilter() {
    if (this.grid && this.initialFilter.length > 0) {
      this.grid.clearFiltering();
      this.initialFilter.forEach((filter) =>
        this.grid?.filterByColumn(filter.field, filter.operator, filter.value),
      );
    }
  }
  onDataBound() {
    this.setLoading(false);
    if (this.grid) {
      this.grid.autoFitColumns();
    }
  }
  onActionBegin(params: FilterSearchBeginEventArgs) {
    this.onOrderChanging(params);
    if (
      params.requestType == 'filterBeforeOpen' &&
      params.filterModel &&
      params.columnName
    ) {
      const config = this.columnConfigurations.find(
        (c) => c.columnName == params.columnName,
      );
      if (config) {
        params.filterModel.options.dataSource = config.items.map((i) => {
          return {
            ...i,
            [params.columnName!]: i.id,
          };
        });
      }
      params.filterModel.options.hideSearchbox = true;
      if ((params as any).columnType == 'boolean' && !config) {
        params.filterModel.options.dataSource = [
          { [params.columnName]: true, text: 'Ja' },
          { [params.columnName]: false, text: 'Nein' },
          { [params.columnName]: null, text: 'null' },
        ];
      }
      params.filterModel.options.filteredColumns =
        params.filterModel.options.filteredColumns?.filter(
          (c: any) => c.field == params.filterModel?.options.field,
        );
    }
  }
  onActionComplete(grid: GridComponent, params: any) {
    // this.onActionBegin(params);
    this.onSortChanged(grid, params);
    this.onFilterChanged(grid, params);
    this.onOrderChanged(grid, params);
    this.onColumnChooserChanged(grid, params);
    if (
      params.requestType == 'filterAfterOpen' &&
      params.filterModel &&
      params.columnName
    ) {
      if (params.columnType === 'number' || params.columnType === 'string') {
        window.setTimeout(() => {
          // Wait 0ms then focus on the input field.
          // Only focus for string and number columns.
          params.filterModel?.dlgDiv
            ?.querySelector(
              params.columnType === 'number'
                ? '.e-numerictextbox'
                : '.e-autocomplete',
            )
            ?.focus();
        });
      }
      // Für Spalten mit Item-Verweis (zb. Location) wird die DialogBox angepasst.
      const filterMenu = (params as FilterMenuRendererArgs).filterModel;
      if (
        filterMenu?.options?.column?.customAttributes &&
        filterMenu.options.column.customAttributes['filterItem'] &&
        filterMenu?.dlgObj
      ) {
        filterMenu.dlgObj.cssClass = 'item-dialog e-filter-popup';
        filterMenu.dlgObj.buttons = [filterMenu?.dlgObj.buttons[1]];
      }

      // Verstecke die Suchbox für die Spalten, die in hideSearchForConfig konfiguriert sind.
      // Hier sind es Status Spalten, die über die Translation geladen werden und daher das Suchfeld nicht funktioniert.
      const config = this.hideSearchForConfig.find(
        (c) => c.columnName == params.columnName,
      );
      if (config && filterMenu && (filterMenu as any).searchBox) {
        (filterMenu as any).searchBox.classList.add('hidden-search-dialog');
      }
      if (params.columnType === 'date' && filterMenu && filterMenu.dlgObj) {
        filterMenu.dlgObj.cssClass = 'date-dialog e-filter-popup';
      }
    }
  }
  private onFilterChanged(grid: GridComponent, params: FilterEventArgs) {
    if (params.requestType != 'filtering') return;
    this.saveState(grid);
  }
  private onSortChanged(grid: GridComponent, params: any) {
    if (params.requestType != 'sorting') return;
    this.saveState(grid);
  }
  private onOrderChanged(grid: GridComponent, params: any) {
    if (params.requestType != 'reorder') return;
    this.saveState(grid);
  }
  private onOrderChanging(params: any) {
    if (params.requestType != 'reorder') return;
    params.toColumnUid = undefined;
  }
  private onColumnChooserChanged(grid: GridComponent, params: any) {
    if (params.requestType != 'columnstate') return;
    this.saveState(grid);
  }
  public getFilterSetting(grid: GridComponent, field: string) {
    var persistData = JSON.parse(grid.getPersistData());
    const model = (
      persistData.filterSettings as FilterSettingsModel
    ).columns?.find((c) => c.field == field);
    return of(model as any);
  }
  private saveState(grid: GridComponent) {
    var persistData = JSON.parse(grid.getPersistData());
    persistData.filterSettings.columns = (
      persistData.filterSettings as FilterSettingsModel
    ).columns?.map((c) => {
      return { ...c };
    });
    let columns: any = {};
    (persistData.columns as Column[])?.forEach((c, i) => {
      columns[c.field] = { index: i, visible: c.visible };
    });
    localStorage.setItem(
      this.storageKey ?? 'undefined',
      JSON.stringify({
        columns: columns,
        sortSettings: persistData.sortSettings,
        filterSettings: persistData.filterSettings,
      }),
    );
  }
  resetState() {
    localStorage.removeItem(this.storageKey ?? 'undefined');
    this.grid?.clearFiltering();
    this.grid?.clearGridActions();
    this.grid?.clearSelection();
    this.grid?.clearSorting();
    this.grid?.clearGrouping();

    (this.grid?.columns as Column[]).forEach((c: Column) =>
      c.field ? this.grid?.reorderColumnByTargetIndex(c.field, c.index) : false,
    );
    this.grid?.filterModule.onActionComplete({ requestType: 'filtering' });
  }
  private setDateInput(
    value: string,
    valInput: HTMLElement,
    datePicker: DatePicker,
    dateRangeInst: DateRangePicker,
  ) {
    const child = valInput.children[0];
    if (child) valInput.removeChild(child);
    if (value == 'inRange') {
      dateRangeInst.appendTo(valInput);
    } else {
      datePicker.appendTo(valInput);
    }
  }
  getDateFilter(fieldName: string) {
    return {
      ui: {
        create: (args: any) => {
          const operators = args.getOptrInstance.dropOptr as DropDownList;
          var flValInput = createElement('input', {
            className: 'flm-input',
          });
          args.target.appendChild(flValInput);
          var date: Date[] = [];
          const grid = args.column.parent;
          grid.filterSettings?.columns?.forEach((col: any) => {
            if (col.field === fieldName) date.push(col.value as Date);
          });
          var dateRangeInst = new DateRangePicker({
            format: 'dd.MM.yyyy',
            startDate: date[0],
            endDate: date[1],
            change: (e) => {
              const config = this.dateColumnConfigurations.find(
                (c) => c.columnName == fieldName,
              );
              if (e != undefined && e.value && config) {
                config.startDate = e.value[0];
                config.endDate = e.value[1];
                config.customFilter = true;
                grid.clearFiltering([fieldName]);
                grid.filterByColumn(
                  fieldName,
                  'inRange',
                  [config.startDate!, config.endDate!],
                  'and',
                );
              }
            },
          });
          const config = this.dateColumnConfigurations.find(
            (c) => c.columnName == fieldName,
          );
          var datePicker = new DatePicker({
            format: 'dd.MM.yyyy',
            value: date[0],
            change: (e) => {
              const config = this.dateColumnConfigurations.find(
                (c) => c.columnName == fieldName,
              );
              if (e != undefined && e.value && config) {
                const startDate = e.value;
                config.startDate = startDate;
              }
            },
          });
          if (config) {
            config.rangePicker = dateRangeInst;
            config.datePicker = datePicker;
          }
          operators.select = (eventArgs: SelectEventArgs) => {
            this.setDateInput(
              eventArgs.itemData?.value as string,
              flValInput,
              datePicker,
              dateRangeInst,
            );
          };
          this.setDateInput(
            operators.value as string,
            flValInput,
            datePicker,
            dateRangeInst,
          );
        },
        write: (args: any) => {
          const c = args.column as Column;
        },
        read: (args: any) => {
          const config = this.dateColumnConfigurations.find(
            (c) => c.columnName == args.column.field,
          );
          if (config && config.datePicker?.value) {
            if (args.operator == 'inRange') {
            } else {
              const grid = args.column.parent;
              grid.clearFiltering([args.column.field]);
              grid.filterByColumn(
                args.column.field,
                args.operator,
                config.datePicker.value,
              );
            }
          }
        },
      },
    };
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
