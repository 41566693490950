import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoadingBarService {
  private loadingCounter = 0;
  private _loading$ = new Subject<boolean>();
  loading$ = this._loading$.asObservable();
  constructor() {}
  setLoading(loading: boolean) {
    if (loading) this.loadingCounter++;
    else this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this._loading$.next(false);
    } else this._loading$.next(true);
  }
}
