import {
  AfterViewInit,
  ElementRef,
  EventEmitter,
  Injectable,
  Output,
  ViewChild,
} from '@angular/core';
import { Toast, ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { BehaviorSubject } from 'rxjs';
export interface ErrorItem {
  title: string;
  details: string;
  status: number;
}
@Injectable({
  providedIn: 'root',
})
export class GlobalErrorService implements AfterViewInit {
  private readonly _errors$ = new BehaviorSubject<ErrorItem[]>([]);
  readonly errors$ = this._errors$.asObservable();
  private snackBarIsOpen = false;
  @Output() retrySend = new EventEmitter();
  @Output() retryDismissed = new EventEmitter();
  // private toast?: Toast = ElementRef;
  @ViewChild('globalErrorToast') toastRef?: ElementRef<Toast>;
  toast?: Toast = this.toastRef?.nativeElement;
  constructor() {}
  ngAfterViewInit(): void {
    if (this.toast && this.toast.close.subscribe) {
      this.toast.close.subscribe((r: any) => {
        this.retryDismissed.emit();
      });
    }
  }

  showErrors() {
    if (this.snackBarIsOpen == false) {
      this.snackBarIsOpen = true;
      this.toast?.show({
        title: 'Toast Title',
        timeOut: 300000,
        position: { X: 'Right', Y: 'Top' },
        showCloseButton: true,
        buttons: [
          {
            model: { content: 'Close All' },
            click: this.dismissError.bind(this),
          },
        ],
        beforeClose: this.beforeClose.bind(this),
      });
    }
  }
  beforeClose() {
    this.snackBarIsOpen = false;
  }
  /* Remove dismissed ErrorItem */
  dismissAll() {
    this._errors$.next([]);
    this.toast?.close();
  }
  dismissError(dismissedErrorItem: ErrorItem) {
    this._errors$.next(
      this._errors$.value.filter((err) => err != dismissedErrorItem),
    );
    if (this._errors$.value.length == 0 && this.toast) {
      this.toast.close();
    }
  }
  addError(addedErrorItem: ErrorItem) {
    if (addedErrorItem.status == 403) {
      ToastUtility.show({
        content: addedErrorItem.details,
        timeOut: 6000,
        position: { X: 'Center', Y: 'Bottom' },
        cssClass: 'error-toast',
      });
    } else {
      this._errors$.next([...this._errors$.value, addedErrorItem]);
      this.showErrors();
    }
  }
  retry() {
    this.dismissAll();
    this.retrySend.emit();
  }
}
