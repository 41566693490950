<ng-container>
  <div style="display: flex; height: -webkit-fill-available">
    <img
      *ngIf="!file || fileType === FileTypes.IMAGE"
      [src]="file?.id | imageAuth: path : thumbnail : size | async"
      [alt]="alt"
      [style.max-height]="size"
      class="sol-img"
    />
    <div *ngIf="fileType !== FileTypes.IMAGE" class="w-full">
      <a
        *ngIf="fileType !== FileTypes.PDF"
        [solDownloadFile]="file"
        [path]="path"
        class="m-auto; text-center;"
        ><div class="mt-7">
          <div
            class="overflow-hidden text-ellipsis text-center"
            style="
              line-break: anywhere;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              display: -webkit-box;
              max-height: -webkit-fill-available;
              -webkit-box-orient: vertical;
            "
          >
            {{ file?.name }}
          </div>
          <i
            *ngIf="iconText"
            class="fa-solid m-auto w-full"
            [ngClass]="{ 'fa-{{iconText}}': true }"
          ></i>
          <i *ngIf="!iconText" class="fa-solid fa-download m-auto w-full"></i>
        </div>
      </a>
      <a
        *ngIf="fileType == FileTypes.PDF"
        [solOpenInNewTab]="file"
        [path]="path"
        class="m-auto; text-center;"
        ><div class="mt-7">
          <div
            class="overflow-hidden text-ellipsis text-center"
            style="
              line-break: anywhere;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              display: -webkit-box;
              max-height: -webkit-fill-available;
              -webkit-box-orient: vertical;
            "
          >
            {{ file?.name }}
          </div>
          <i
            *ngIf="iconText"
            class="fa-solid m-auto w-full"
            [ngClass]="{ 'fa-{{iconText}}': true }"
          ></i>
        </div>
      </a>
    </div>
  </div>
</ng-container>
