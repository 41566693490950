<ejs-sidebar
  #sidebar
  class="{{ customClass }} top-12 bg-background-menu text-foreground-menu"
  [type]="'Auto'"
  [enableGestures]="false"
  [enablePersistence]="true"
> 
  <ejs-treeview
    #treeview
    class="w-full"
    [animation]="{
      expand: { effect: 'SlideDown', duration: 100, easing: 'linear' },
      collapse: { effect: 'SlideUp', duration: 100, easing: 'linear' }
    }"
    [fields]="fields$ | async"
    expandOn="Click"
    [cssClass]="'custom'"
    [fullRowSelect]="false"
    (nodeClicked)="onItemClicked(treeview, $event)"
  >
    <ng-template #nodeTemplate="" let-data="">
      <a *ngIf="data.external; else linkTpl" [href]="data.link">
        <i
          *ngIf="data.iconText"
          class="{{
            data.iconText
          }} gradient-icon mr-2 w-[26px] align-center text-lg text-center font-normal"
        ></i>
        {{ data.text }}
      </a>
      <ng-template #linkTpl>
        <a [routerLink]="data.link">
          <i
            *ngIf="data.iconText"
            class="{{
              data.iconText
            }} gradient-icon mr-2 w-[26px] align-center text-lg text-center font-normal"
          ></i>
          {{ data.text }}
        </a>
      </ng-template>
    </ng-template>
  </ejs-treeview>
</ejs-sidebar>
<ng-content></ng-content>
