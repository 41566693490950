import { Pipe, PipeTransform } from '@angular/core';
import {
  Column,
  FilterSettingsModel,
  GridComponent,
} from '@syncfusion/ej2-angular-grids';
import { BaseStoreService, Item } from '../../services/base-store.service';
import { of } from 'rxjs/internal/observable/of';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

@Pipe({
  standalone: true,
  name: 'getSfFilter',
})
export class GetSfFilterPipe implements PipeTransform {
  transform<TGetDto extends Item, TPutDto, TPostDto>(
    field: string,
    ...args: any[]
  ): Observable<{ item: any | null | undefined }> {
    const grid = args[0] as GridComponent;
    const store = args[1] as BaseStoreService<TGetDto, TPutDto, TPostDto>;
    if (grid && store) {
      var persistData = JSON.parse(grid.getPersistData());
      const model = (
        persistData.filterSettings as FilterSettingsModel
      ).columns?.find((c) => c.field == field);
      const column = (grid.columns as Column[]).find((c) => c.field == field);
      if (column) column.customAttributes = { filterItem: true };
      return of(model?.value).pipe(
        switchMap((value) => {
          if (value) {
            return store.getSingle(value as number);
          }
          return of(undefined);
        }),
        map((item) => {
          return { item: item as any };
        }),
      );
    }
    return of({ item: undefined });
  }
}
