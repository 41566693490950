import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  shareReplay,
  tap,
} from 'rxjs';
import { AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { AuthUserService } from '../services/auth-user.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUserPostDto } from '../../dto/auth-user-post-dto';
import { AdminService } from '../services/admin.service';
import { AuthUserPutDto } from '../../dto/auth-user-put-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountDetailsService implements OnDestroy {
  protected onDestroy$ = new Subject();

  private _user$: BehaviorSubject<AuthUserGetDto | undefined> =
    new BehaviorSubject<AuthUserGetDto | undefined>(undefined);
  user$: Observable<AuthUserGetDto | undefined> = this._user$
    .asObservable()
    .pipe(
      distinctUntilChanged(),
      tap((item) => (this.user = item)),
      shareReplay(1),
    );
  user: AuthUserGetDto | undefined;
  constructor(
    private userService: AuthUserService,
    private adminService: AdminService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
  setItem(item: AuthUserGetDto | undefined) {
    this._user$.next(item);
  }
  updateItem(itemPutDto: AuthUserPutDto) {
    return this.userService
      .updateCurrentUser(itemPutDto)
      .pipe(
        tap((r) =>
          this.setItem({ ...(this._user$.value as any), ...itemPutDto }),
        ),
      );
  }
  createItem(
    itemPostDto: AuthUserPostDto,
    withRouteChange = true,
  ): Observable<AuthUserGetDto> {
    return this.adminService.createUser(itemPostDto).pipe(
      tap((item) => {
        this.setItem(item);
        if (withRouteChange) this.setRouteId(item.id);
      }),
    );
  }
  setRouteId(id: number) {
    this.location.go(this.location.path() + '/' + id?.toString());
  }
  removeRouteId() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
