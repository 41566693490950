import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ComponentStateService,
  LoadingScreenComponent,
} from 'ngx-common-solution';
import { Observable, finalize, map } from 'rxjs';
import { AdminService } from '../services/admin.service';
import { AuthenticationStoreService } from '../stores/authentication-store.service';
import { AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { ApiKeyPutDto } from '../../dto/api-key-put-dto';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ClipboardModule } from '@angular/cdk/clipboard';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    LoadingScreenComponent,
    ClipboardModule,
  ],
  selector: 'auth-api-key-details',
  templateUrl: './api-key-details.component.html',
  styleUrls: ['./api-key-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStateService],
})
export class ApiKeyDetailsComponent {
  loading$: Observable<boolean>;
  @Input() user?: AuthUserGetDto | null;
  @Output() apiKeyGenerated = new EventEmitter<AuthUserGetDto>();
  @Output() apiKeyDeleted = new EventEmitter<AuthUserGetDto>();
  apiKey?: string;
  currentUser$: Observable<AuthUserGetDto | null>;
  constructor(
    private componentState: ComponentStateService,
    private adminService: AdminService,
    authStore: AuthenticationStoreService,
  ) {
    this.loading$ = componentState.loading$;
    this.currentUser$ = authStore.authUser$;
  }
  onGetNewApiKey() {
    if (this.user) {
      const prefix = this.generateId(8);
      const apiKey =
        prefix + crypto.randomUUID().toString().replaceAll('-', '');
      this.componentState.setLoading(true);
      this.adminService
        .addApiKey(this.user.id, {
          prefix: prefix,
          apiKey: apiKey,
        } as ApiKeyPutDto)
        .pipe(finalize(() => this.componentState.setLoading(false)))
        .subscribe((r) => {
          this.apiKey = apiKey;
          this.apiKeyGenerated.emit({
            ...this.user,
            apiKeyPrefix: prefix,
          } as AuthUserGetDto);
          this.componentState.setLoading(false);
        });
    }
  }
  onDeleteApiKey() {
    if (this.user) {
      this.componentState.setLoading(true);
      this.adminService
        .deleteApiKey(this.user.id)
        .pipe(finalize(() => this.componentState.setLoading(false)))
        .subscribe((r) => {
          this.apiKey = undefined;
          this.apiKeyDeleted.emit({
            ...this.user,
            apiKeyPrefix: undefined,
          } as AuthUserGetDto);
          this.componentState.setLoading(false);
        });
    }
  }
  dec2hex(dec: any) {
    return dec.toString(16).padStart(2, '0');
  }
  generateId(len: number) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, this.dec2hex).join('');
  }
}
