import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { getQueryMap } from 'ngx-common-solution';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { AuthKeys } from '../tokens';
import { AuthenticationStatusService } from './authentication-status.service';
@Injectable({
  providedIn: 'root',
})
export class LoginLogoutService {
  private permittedUrls = [
    '/reset-authenticator',
    '/register',
    '/login',
    '/reset-password',
    '/reset',
    '/send-reset',
    '/invitation',
    '/logout',
    '/code',
  ];
  constructor(
    private authenticationStatusService: AuthenticationStatusService,
    private router: Router,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
  ) {
    window.addEventListener('storage', this.handleStorageEvent, false);
  }
  logout(lastUrl: string = '') {
    localStorage.removeItem(AuthKeys.JWT_TOKEN_KEY);
    localStorage.removeItem(AuthKeys.JWT_REFRESH_TOKEN_KEY);
    localStorage.removeItem(AuthKeys.USER_ID_KEY);
    sessionStorage.clear();
    this.oauthService.logOut();
    this.authenticationStatusService.setAuthenticated(false);
    this.goToLogin(lastUrl);
  }

  private stopQueryParamsSub$ = new Subject();
  goToLogin(lastUrl: string, withoutRedirect: boolean = false) {
    this.route.queryParams
      .pipe(
        takeUntil(this.stopQueryParamsSub$),
        debounceTime(100),
        map((params) => {
          const url = params['redirectURL']
            ? params['redirectURL']
            : lastUrl
              ? lastUrl
              : this.router.routerState.snapshot.url;
          if (url == '/') return '';
          else return url;
        }),
        distinctUntilChanged(),
      )
      .subscribe((redirectURL) => {
        if (
          redirectURL.startsWith('/?code') ||
          redirectURL.startsWith('/?state')
        ) {
          const code = redirectURL.substring(2);
          const qmap = getQueryMap(code);
          this.router.navigate(['code'], {
            queryParams: qmap,
          });
        } else {
          if (redirectURL && redirectURL != '/login') {
            if (!this.permittedUrls.includes(redirectURL.split('?')[0])) {
              if (withoutRedirect) {
                this.router.navigate(['login']);
              } else {
                this.router.navigate(['login'], {
                  queryParams: { redirectURL: encodeURI(redirectURL) },
                  // queryParamsHandling: 'preserve',
                });
              }
            } else {
              this.router.navigate(['login']);
            }
          } else {
            this.router.navigate(['login']);
          }
        }
        this.stopQueryParamsSub$.next(true);
        this.stopQueryParamsSub$.complete();
      });
  }

  private handleStorageEvent = (event: StorageEvent): void => {
    if (event.storageArea != localStorage) return;
    if (event.key === AuthKeys.JWT_TOKEN_KEY) {
      if (event.newValue) {
      }
      let tokenValid = false;
      this.authenticationStatusService.isAuthenticated$
        .pipe(take(1))
        .subscribe((isAuth) => {
          if (event.newValue && isAuth == false) {
            // tokenValid = this.checkToken('');
            this.reloadPage();
          } else if (!tokenValid) {
            this.goToLogin('');
          }
        });
    }
  };
  private reloadPage() {
    location.reload();
  }
}
