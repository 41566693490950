<div id="toolbar" class="my-1 flex gap-2">
  <div class="ml-2 flex w-full">
    <div class="my-auto flex gap-2">
      <ng-container *checkPermissions="let c">
        <a
          *ngIf="!createPermission || (c(createPermission) | async)"
          [routerLink]="newLink"
          ejs-button
          class="icon-button px-2"
          (click)="onCreateClick()"
        >
          <div><i class="fa-solid fa-plus text-xl"></i></div>
        </a>
      </ng-container>
      <div class="my-auto text-2xl">{{ title }}</div>
    </div>
  </div>
  <button ejs-button (click)="showColumnChooser()">
    Spalten aus-/einblenden
  </button>
  <button ejs-button (click)="resetFilter()">Einstellungen zurücksetzen</button>
  <button ejs-button (click)="exportExcel()">Export Excel</button>
  <button ejs-button (click)="exportPdf()">Export PDF</button>
  <button ejs-button (click)="print()">Drucken</button>
  <button ejs-button (click)="refresh()" class="icon-button mr-4">
    <div>
      <i class="fa-regular fa-arrows-rotate"></i>
    </div>
  </button>
</div>
