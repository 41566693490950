import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { AuthUserService } from '../services/auth-user.service';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EnumUserStatuses } from '../../dto/enum-user-statuses';
import { AccountDetailsService } from './account-details.service';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  finalize,
  of,
  takeUntil,
} from 'rxjs';
import {
  ComponentStateService,
  EnumTranslatorPipe,
  FormFocusDirective,
  KeyValueEnumPipe,
  LoadingScreenComponent,
  TextboxComponent,
} from 'ngx-common-solution';
import { AdminService } from '../services/admin.service';
import { TokenStoreService } from '../stores/token-store.service';
import { AuthUserPutDto } from '../../dto/auth-user-put-dto';
import { AuthUserPostDto } from '../../dto/auth-user-post-dto';
import { AuthenticationStoreService } from '../stores/authentication-store.service';
import { PasswordPattern } from '../../password-pattern';
import { Dialog, DialogModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { AccountSetPasswordComponent } from '../account-set-password/account-set-password.component';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { ApiKeyDetailsComponent } from '../api-key-details/api-key-details.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ListViewModule,
    ReactiveFormsModule,
    DropDownListModule,
    ButtonModule,
    FormFocusDirective,
    DialogModule,
    AccountSetPasswordComponent,
    TextboxComponent,
    LoadingScreenComponent,
    SwitchModule,
    EnumTranslatorPipe,
    KeyValueEnumPipe,
    ApiKeyDetailsComponent,
  ],
  selector: 'auth-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStateService, AccountDetailsService],
})
export class AccountDetailsComponent implements OnDestroy, OnChanges {
  private onDestroy$ = new Subject();
  EnumUserStatuses = EnumUserStatuses;
  @Input() id?: number;
  @Input() showCurrentAccount?: boolean;
  form = this.fb.group({
    id: new FormControl<number | undefined>(undefined),
    userName: ['', [Validators.required]],
    email: new FormControl<string | undefined>(undefined, [Validators.email]),
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    userStatusId: [EnumUserStatuses.NewPasswordRequired, [Validators.required]],
    emailConfirmed: new FormControl(false),
    twoFactorEnabled: new FormControl(false),
    organization: new FormControl<string | undefined>(undefined),
  });
  initialPasswordForm = this.fb.group({
    password: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.pattern(PasswordPattern),
    ]),
  });
  setOrganizationForm = this.fb.group({
    organization: new FormControl<string | undefined>(undefined),
  });
  loading$: Observable<boolean>;
  errors$: Observable<string[]>;
  user$ = this.detailsService.user$;
  currentUserId = this.tokenStore.getUserId();
  currentAuthUser$: Observable<AuthUserGetDto | null>;
  constructor(
    private userService: AuthUserService,
    private adminService: AdminService,
    private fb: FormBuilder,
    private detailsService: AccountDetailsService,
    private componentState: ComponentStateService,
    private tokenStore: TokenStoreService,
    authStore: AuthenticationStoreService,
  ) {
    this.loading$ = componentState.loading$;
    this.errors$ = componentState.errors$;
    detailsService.user$.pipe(takeUntil(this.onDestroy$)).subscribe((u) => {
      if (u) {
        this.form.patchValue(u);
        this.setOrganizationForm.patchValue(u);
      }
    });
    this.currentAuthUser$ = authStore.authUser$;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showCurrentAccount'] || changes['id']) {
      if (this.showCurrentAccount) {
        this.userService.getCurrentAuthUser().subscribe((item) => {
          this.detailsService.setItem(item);
        });
      } else if (this.id) {
        this.adminService.getSingle(this.id).subscribe((item) => {
          this.detailsService.setItem(item);
        });
      }
    }
  }
  submit(dialog: Dialog) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.form.value?.id) {
      this.componentState.setLoading(true);
      const putDto: AuthUserPutDto = {
        firstName: this.form.value.firstName!,
        lastName: this.form.value.lastName!,
        userStatusId: this.form.value.userStatusId!,
        email: this.form.value.email ?? undefined,
      };
      if (this.showCurrentAccount) {
        this.detailsService
          .updateItem(putDto)
          .pipe(finalize(() => this.componentState.setLoading(false)))
          .subscribe();
      } else {
        this.adminService
          .update(this.form.value.id, putDto)
          .pipe(
            finalize(() => this.componentState.setLoading(false)),
            catchError((err) => this.checkResponseForError(err)),
          )
          .subscribe();
      }
    } else {
      if (!this.showCurrentAccount) {
        dialog.visible = true;
      }
    }
  }

  submitInitialPassword(passwordDialog: Dialog) {
    if (this.initialPasswordForm.invalid || this.form.invalid) {
      this.initialPasswordForm.markAllAsTouched();
      this.form.markAllAsTouched();
      return;
    }
    this.componentState.setLoading(true);
    const post: AuthUserPostDto = {
      userName: this.form.value.userName!,
      email: this.form.value.email ?? undefined,
      firstName: this.form.value.firstName!,
      lastName: this.form.value.lastName!,
      userStatusId: this.form.value.userStatusId!,
      password: this.initialPasswordForm.value.password!,
    };
    this.detailsService
      .createItem(post)
      .pipe(
        finalize(() => this.componentState.setLoading(false)),
        catchError((err) => this.checkResponseForError(err)),
      )
      .subscribe((r) => passwordDialog.hide());
  }
  submitWithoutPassword(passwordDialog: Dialog) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.componentState.setLoading(true);
    const post: AuthUserPostDto = {
      userName: this.form.value.userName!,
      email: this.form.value.email ?? undefined,
      firstName: this.form.value.firstName!,
      lastName: this.form.value.lastName!,
      userStatusId: this.form.value.userStatusId!,
      password: undefined,
    };
    this.detailsService
      .createItem(post)
      .pipe(
        finalize(() => this.componentState.setLoading(false)),
        catchError((err) => this.checkResponseForError(err)),
      )
      .subscribe((r) => passwordDialog.hide());
  }
  submitOrganization(setOrganizationDialog: Dialog) {
    if (this.setOrganizationForm.invalid) {
      this.setOrganizationForm.markAllAsTouched();
      return;
    }
    this.componentState.setLoading(true);
    this.adminService
      .setOrganization(this.form.value.id!, {
        organization: this.setOrganizationForm.value.organization ?? undefined,
      })
      .pipe(finalize(() => this.componentState.setLoading(false)))
      .subscribe((r) => {
        setOrganizationDialog.hide();
        this.form.patchValue({
          organization: this.setOrganizationForm.value.organization,
        });
      });
  }
  checkResponseForError(err: any) {
    if (err.status == 400) {
      const res = err.error as {
        code: string;
        description: string;
      }[];
      this.componentState.setErrors(res.map((r) => r.description));
      return EMPTY;
    }
    return of(err);
  }
  onPasswordChanged($event: any) {
    if (this.detailsService.user) {
      this.detailsService.setItem({
        ...this.detailsService.user,
        userStatusId: EnumUserStatuses.NewPasswordRequired,
      });
    }
  }
  onApiKeyDeleted(user: any) {
    this.detailsService.setItem(user);
  }
  onApiKeyGenerated(user: any) {
    this.detailsService.setItem(user);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
