import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import {
  GlobalErrorService,
  GlobalLoadingBarService,
  ProblemDetails,
} from 'ngx-common-solution';
import {
  EMPTY,
  Observable,
  catchError,
  finalize,
  switchMap,
  throwError,
} from 'rxjs';
import { LoginLogoutService } from './stores/login-logout.service';
import { RefreshTokenService } from './stores/refresh-token.service';
import { getAuthToken } from './tokens';
import { AUTHENTICATION_DEFAULT_OPTIONS } from './Tokens/tokens';

export const authInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const globalLoadingBarService = inject(GlobalLoadingBarService);
  globalLoadingBarService.setLoading(true);

  const loginLogoutService = inject(LoginLogoutService);
  const globalErrorService = inject(GlobalErrorService);
  const refreshTokenService = inject(RefreshTokenService);
  const config = inject(AUTHENTICATION_DEFAULT_OPTIONS);

  // if (isAllowedDomain(request, config.allowedDomains)) {
  //   request = request.clone({
  //     setHeaders: { Authorization: `Bearer ${getAuthToken()}` },
  //   });
  // }
  return next(request).pipe(
    finalize(() => globalLoadingBarService.setLoading(false)),
    catchError((errorResponse) => {
      return handleResponseError(
        globalLoadingBarService,
        loginLogoutService,
        globalErrorService,
        refreshTokenService,
        errorResponse,
        request,
        next,
      );
    }),
  );
};

function handleResponseError(
  globalLoadingBarService: GlobalLoadingBarService,
  loginLogoutService: LoginLogoutService,
  globalErrorService: GlobalErrorService,
  refreshTokenService: RefreshTokenService,
  errorResponse: any,
  request: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> {
  // Business error
  if (errorResponse.status === 400) {
    // Show message
  }

  // Invalid token error
  else if (errorResponse.status === 401) {
    const err: HttpErrorResponse = errorResponse;
    if (err.headers.has('is-token-expired')) {
      if (err.headers.get('is-token-expired') == 'true') {
        return refreshTokenService.refreshToken().pipe(
          switchMap((res) => {
            if (res) {
              request = request.clone({
                setHeaders: { Authorization: `Bearer ${getAuthToken()}` },
              });
              return next(request);
            } else {
              return throwError(() => errorResponse);
            }
          }),
          catchError((e) => {
            if (e.status !== 401) {
              return handleResponseError(
                globalLoadingBarService,
                loginLogoutService,
                globalErrorService,
                refreshTokenService,
                e,
                request,
                next,
              );
            } else {
              loginLogoutService.logout();
              return EMPTY;
            }
          }),
        );
      }
    }
    loginLogoutService.logout();
    return EMPTY;
  }

  // Access denied error
  else if (errorResponse.status === 403) {
    // Show message
    // Logout
    // console.log("Logout");
    // this.authService.logout();
    // this.router.navigate(["login"]);
  }

  // Server error
  else if (errorResponse.status === 500) {
    // Show message
  }

  // Maintenance error
  else if (errorResponse.status === 503) {
    // Show message
    // Redirect to the maintenance page
  }
  const errorDetails = errorResponse.error as ProblemDetails;
  if (
    !errorResponse.url.includes('assets/icons/') &&
    //errorResponse.status != 403 &&
    errorResponse.status != 400 &&
    errorResponse.status != 401 &&
    errorResponse.status != 404 &&
    errorResponse.status != 422
  ) {
    globalErrorService.addError({
      title:
        errorResponse.status == 0
          ? 'Keine Verbindung zum Server möglich'
          : errorDetails?.title ?? errorResponse.message ?? errorResponse.error,
      status: errorResponse.status,
      details:
        errorDetails?.detail ?? errorResponse.message ?? errorResponse.error,
    });
    // return merge(
    //   throwError(() => errorResponse),
    //   globalErrorService.retrySend.pipe(
    //     finalize(() => globalLoadingBarService.setLoading(false)),
    //     takeUntil(globalErrorService.retryDismissed),
    //     take(1),
    //     switchMap(() => {
    //       globalLoadingBarService.setLoading(true);
    //       return next(request);
    //     }),
    //     catchError((e) => {
    //       return handleResponseError(
    //         authenticationStore,
    //         globalErrorService,
    //         globalLoadingBarService,
    //         e,
    //         request,
    //         next,
    //       );
    //     }),
    //   ),
    // );
  }
  return throwError(() => errorResponse);
}
function isAllowedDomain(
  request: HttpRequest<any>,
  allowedDomains: string[],
): boolean {
  const requestUrl: URL = new URL(request.url, document.location.origin);

  // If the host equals the current window origin,
  // the domain is allowed by default
  if (requestUrl.host === document.location.host) {
    return true;
  }

  // If not the current domain, check the allowed list
  const hostName = `${requestUrl.hostname}${
    requestUrl.port && !['80', '443'].includes(requestUrl.port)
      ? ':' + requestUrl.port
      : ''
  }`;

  return (
    allowedDomains.findIndex((domain: any) =>
      typeof domain === 'string'
        ? domain === hostName
        : domain instanceof RegExp
          ? domain.test(hostName)
          : false,
    ) > -1
  );
}
