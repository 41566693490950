import { Injectable } from '@angular/core';
import { LoginResultDto } from '../../dto/login-result-dto';
import { AuthKeys } from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class TokenStoreService {
  constructor() {}

  saveUserId(userId: number | null) {
    if (userId) localStorage.setItem(AuthKeys.USER_ID_KEY, userId.toString());
    else localStorage.removeItem(AuthKeys.USER_ID_KEY);
  }
  getUserId(): number {
    const userId = parseInt(localStorage.getItem(AuthKeys.USER_ID_KEY) ?? '0');
    return userId;
  }
  saveRefreshToken(token: string): void {
    localStorage.setItem(AuthKeys.JWT_REFRESH_TOKEN_KEY, token);
  }
  getRefreshToken(): string | null {
    return localStorage.getItem(AuthKeys.JWT_REFRESH_TOKEN_KEY);
  }
  saveToken(token: string): void {
    localStorage.setItem(AuthKeys.JWT_TOKEN_KEY, token);
  }
  getToken(): string | null {
    return localStorage.getItem(AuthKeys.JWT_TOKEN_KEY);
  }

  saveLoginDataOnSuccess(loginSuccessData: LoginResultDto | null) {
    if (loginSuccessData) {
      if (loginSuccessData.token) this.saveToken(loginSuccessData.token);
      if (loginSuccessData.refreshToken) {
        this.saveRefreshToken(loginSuccessData.refreshToken);
      }
    }
  }
}
