import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs';
import { SelectorConfig } from '../selector-config';
import { ComponentStateService } from './component-state.service';
import { Item } from './base-store.service';
import { PredicateModel } from '@syncfusion/ej2-angular-grids';

@Injectable({
  providedIn: 'root',
})
export class SearchStoreService<TGetDto extends Item, TPutDto, TPostDto>
  implements OnDestroy
{
  private onDestroy$ = new Subject();
  private _searchValue$ = new BehaviorSubject<string | null>(null);
  searchValue$ = this._searchValue$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );
  private _selectorConfig$ = new BehaviorSubject<SelectorConfig<
    TGetDto,
    TPutDto,
    TPostDto
  > | null>(null);
  selectorConfig$: Observable<SelectorConfig<TGetDto, TPutDto, TPostDto>> =
    this._selectorConfig$.pipe(
      filter((s) => s !== null),
      map((s) => s!),
      shareReplay(1),
    );
  loading$: Observable<boolean>;
  filteredItems$: Observable<TGetDto[]>;
  filterModel: any;
  dateKeys = ['createdOn'];
  constructor(private componentStateService: ComponentStateService) {
    this.loading$ = componentStateService.loading$;
    const itemCount$ = this.selectorConfig$.pipe(
      switchMap((selectorConfig) => selectorConfig.searchStore$.items$),
      map((items) => items.length),
      distinctUntilChanged(),
    );
    this.filteredItems$ = this.selectorConfig$
      .pipe(
        switchMap((selectorConfig) =>
          combineLatest([
            selectorConfig.searchStore$.items$.pipe(distinctUntilChanged()),
            this.searchValue$,
          ]),
        ),
      )
      .pipe(
        map(([items, searchValue]) =>
          searchValue
            ? items.filter((item) => this.doesFilterPass(searchValue, item))
            : items,
        ),
      );

    const filteredItemCount$ = this.filteredItems$.pipe(
      map((items) => items.length),
      distinctUntilChanged(),
    );
    combineLatest([itemCount$, filteredItemCount$, this.searchValue$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([itemCount, filteredItemCount, searchValue]) => {
        if (itemCount > 950 && filteredItemCount < 50) {
          const filter = searchValue
            ? this._selectorConfig$.value?.searchStore$?.getFilterPredicateModel(
                this.getFilterModel(searchValue),
              )
            : null;
          if (filter) filter.operator = 'or';
          if (JSON.stringify(filter) !== JSON.stringify(this.filterModel)) {
            this.filterModel = filter;
            this._selectorConfig$.value?.searchStore$?.setSearchFilter(
              filter ?? {},
            );
          }
        }
      });
  }
  doesFilterPass(searchValue: string, item: any): boolean {
    // make sure each word passes separately, ie search for firstname, lastname
    let passed = false;

    searchValue
      .toLowerCase()
      .split(' ')
      .forEach((filterWord) => {
        if (filterWord) {
          Object.keys(item).forEach((key) => {
            const value = item[key];
            if (
              (!this.dateKeys.includes(key) || filterWord.length >= 3) &&
              value &&
              value.toString().toLowerCase().indexOf(filterWord) >= 0
            ) {
              passed = true;
            }
          });
        }
      });

    return passed;
  }
  setSelectorConfig(
    selectorConfig: SelectorConfig<TGetDto, TPutDto, TPostDto>,
  ) {
    this._selectorConfig$.next(selectorConfig);
  }
  setSearchValue(value: string | null) {
    this._searchValue$.next(value);
  }
  getFilterModel(searchValue: string) {
    var model: PredicateModel[] = [];
    if (searchValue) {
      this._selectorConfig$.value?.columnDefs.forEach((columnDef) => {
        if (columnDef.searchPropertyName) {
          switch (columnDef.type) {
            case 'text': {
              const m = this.getDefaultTextFilterModel(
                columnDef.searchPropertyName,
                searchValue,
              );
              if (m) model.push(m);
              break;
            }
            case 'number': {
              const m = this.getDefaultNumberFilterModel(
                columnDef.searchPropertyName,
                searchValue,
              );
              if (m) model.push(m);
              break;
            }
          }
        }
      });
    }
    return model;
  }
  getDefaultTextFilterModel(
    field: string,
    value: string,
  ): PredicateModel | null {
    const model: PredicateModel = {
      value: value,
      operator: 'contains',
      field,
    };
    return model;
  }
  getDefaultNumberFilterModel(
    field: string,
    value: string,
  ): PredicateModel | null {
    const numValue = Number(value);
    if (Number.isNaN(numValue)) return null;
    const model: PredicateModel = {
      value: numValue,
      operator: 'equals',
      field,
    };
    return model;
  }
  getDefaultSetFilterModel(
    field: string,
    value: string,
  ): PredicateModel | null {
    const model: PredicateModel = {
      value: value,
      operator: 'equals',
      field,
    };
    return model;
  }
  getDefaultDateFilterModel(
    field: string,
    value: string,
  ): PredicateModel | null {
    const model: PredicateModel = {
      value: value,
      operator: 'equals',
      field,
    };
    return model;
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
