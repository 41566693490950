import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule, forwardRef, Directive, ContentChildren, ContentChild } from '@angular/core';
import { setValue, ComponentBase, ComponentMixins, FormBase, ComplexBase, ArrayBase, Template } from '@syncfusion/ej2-angular-base';
import { Button, CheckBox, RadioButton, Switch, ChipList, Fab, SpeedDial } from '@syncfusion/ej2-buttons';
const _c0 = ["ejs-button", ""];
const _c1 = ["*"];
const _c2 = ["ejs-fab", ""];
const _c3 = ["itemTemplate"];
const _c4 = ["popupTemplate"];
const _c5 = ["ejs-speeddial", ""];
export * from '@syncfusion/ej2-buttons';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const inputs$6 = ['content', 'cssClass', 'disabled', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'iconCss', 'iconPosition', 'isPrimary', 'isToggle', 'locale'];
const outputs$8 = ['created'];
const twoWays$6 = [];
/**
 * Represents the Angular Button Component.
 * ```html
 * <button ejs-button>Button</button>
 * ```
 */
let ButtonComponent = class ButtonComponent extends Button {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$8);
    this.addTwoWay.call(this, twoWays$6);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
ButtonComponent.ɵfac = function ButtonComponent_Factory(t) {
  return new (t || ButtonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ButtonComponent,
  selectors: [["", "ejs-button", ""]],
  inputs: {
    content: "content",
    cssClass: "cssClass",
    disabled: "disabled",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    iconCss: "iconCss",
    iconPosition: "iconPosition",
    isPrimary: "isPrimary",
    isToggle: "isToggle",
    locale: "locale"
  },
  outputs: {
    created: "created"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c0,
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function ButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
ButtonComponent = __decorate([ComponentMixins([ComponentBase])], ButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-button]',
      inputs: inputs$6,
      outputs: outputs$8,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Button component.
 */
class ButtonModule {}
ButtonModule.ɵfac = function ButtonModule_Factory(t) {
  return new (t || ButtonModule)();
};
ButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ButtonModule
});
ButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ButtonComponent],
      exports: [ButtonComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Button component with providers.
 */
class ButtonAllModule {}
ButtonAllModule.ɵfac = function ButtonAllModule_Factory(t) {
  return new (t || ButtonAllModule)();
};
ButtonAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ButtonAllModule
});
ButtonAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ButtonModule], ButtonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ButtonModule],
      exports: [ButtonModule],
      providers: []
    }]
  }], null, null);
})();
var CheckBoxComponent_1;
const inputs$5 = ['checked', 'cssClass', 'disabled', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'htmlAttributes', 'indeterminate', 'label', 'labelPosition', 'locale', 'name', 'value'];
const outputs$7 = ['focus', 'blur', 'change', 'created', 'checkedChange', 'indeterminateChange'];
const twoWays$5 = ['checked', 'indeterminate'];
/**
 * Represents the Angular CheckBox Component.
 * ```html
 * <ejs-checkbox label='Default'></ejs-checkbox>
 * ```
 */
let CheckBoxComponent = CheckBoxComponent_1 = class CheckBoxComponent extends CheckBox {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$7);
    this.addTwoWay.call(this, twoWays$5);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
CheckBoxComponent.ɵfac = function CheckBoxComponent_Factory(t) {
  return new (t || CheckBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
CheckBoxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CheckBoxComponent,
  selectors: [["ejs-checkbox"]],
  inputs: {
    checked: "checked",
    cssClass: "cssClass",
    disabled: "disabled",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    htmlAttributes: "htmlAttributes",
    indeterminate: "indeterminate",
    label: "label",
    labelPosition: "labelPosition",
    locale: "locale",
    name: "name",
    value: "value"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    change: "change",
    created: "created",
    checkedChange: "checkedChange",
    indeterminateChange: "indeterminateChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckBoxComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CheckBoxComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
CheckBoxComponent = CheckBoxComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], CheckBoxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckBoxComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-checkbox',
      inputs: inputs$5,
      outputs: outputs$7,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckBoxComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the CheckBox component.
 */
class CheckBoxModule {}
CheckBoxModule.ɵfac = function CheckBoxModule_Factory(t) {
  return new (t || CheckBoxModule)();
};
CheckBoxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CheckBoxModule
});
CheckBoxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckBoxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [CheckBoxComponent],
      exports: [CheckBoxComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the CheckBox component with providers.
 */
class CheckBoxAllModule {}
CheckBoxAllModule.ɵfac = function CheckBoxAllModule_Factory(t) {
  return new (t || CheckBoxAllModule)();
};
CheckBoxAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CheckBoxAllModule
});
CheckBoxAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, CheckBoxModule], CheckBoxModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckBoxAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, CheckBoxModule],
      exports: [CheckBoxModule],
      providers: []
    }]
  }], null, null);
})();
var RadioButtonComponent_1;
const inputs$4 = ['checked', 'cssClass', 'disabled', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'htmlAttributes', 'label', 'labelPosition', 'locale', 'name', 'value'];
const outputs$6 = ['focus', 'blur', 'change', 'created', 'valueChange'];
const twoWays$4 = ['value'];
/**
 * Represents the Angular RadioButton Component.
 * ```html
 * <ejs-radiobutton label='Default'></ejs-radiobutton>
 * ```
 */
let RadioButtonComponent = RadioButtonComponent_1 = class RadioButtonComponent extends RadioButton {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$6);
    this.addTwoWay.call(this, twoWays$4);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
RadioButtonComponent.ɵfac = function RadioButtonComponent_Factory(t) {
  return new (t || RadioButtonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
RadioButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadioButtonComponent,
  selectors: [["ejs-radiobutton"]],
  inputs: {
    checked: "checked",
    cssClass: "cssClass",
    disabled: "disabled",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    htmlAttributes: "htmlAttributes",
    label: "label",
    labelPosition: "labelPosition",
    locale: "locale",
    name: "name",
    value: "value"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    change: "change",
    created: "created",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioButtonComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function RadioButtonComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
RadioButtonComponent = RadioButtonComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], RadioButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioButtonComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-radiobutton',
      inputs: inputs$4,
      outputs: outputs$6,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RadioButtonComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the RadioButton component.
 */
class RadioButtonModule {}
RadioButtonModule.ɵfac = function RadioButtonModule_Factory(t) {
  return new (t || RadioButtonModule)();
};
RadioButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RadioButtonModule
});
RadioButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [RadioButtonComponent],
      exports: [RadioButtonComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the RadioButton component with providers.
 */
class RadioButtonAllModule {}
RadioButtonAllModule.ɵfac = function RadioButtonAllModule_Factory(t) {
  return new (t || RadioButtonAllModule)();
};
RadioButtonAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RadioButtonAllModule
});
RadioButtonAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, RadioButtonModule], RadioButtonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadioButtonAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RadioButtonModule],
      exports: [RadioButtonModule],
      providers: []
    }]
  }], null, null);
})();
var SwitchComponent_1;
const inputs$3 = ['checked', 'cssClass', 'disabled', 'enablePersistence', 'enableRtl', 'htmlAttributes', 'locale', 'name', 'offLabel', 'onLabel', 'value'];
const outputs$5 = ['focus', 'blur', 'change', 'created', 'checkedChange'];
const twoWays$3 = ['checked'];
/**
 * Represents the Angular Switch Component.
 * ```html
 * <ejs-switch></ejs-switch>
 * ```
 */
let SwitchComponent = SwitchComponent_1 = class SwitchComponent extends Switch {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$5);
    this.addTwoWay.call(this, twoWays$3);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
SwitchComponent.ɵfac = function SwitchComponent_Factory(t) {
  return new (t || SwitchComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
SwitchComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SwitchComponent,
  selectors: [["ejs-switch"]],
  inputs: {
    checked: "checked",
    cssClass: "cssClass",
    disabled: "disabled",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    htmlAttributes: "htmlAttributes",
    locale: "locale",
    name: "name",
    offLabel: "offLabel",
    onLabel: "onLabel",
    value: "value"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    change: "change",
    created: "created",
    checkedChange: "checkedChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SwitchComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function SwitchComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
SwitchComponent = SwitchComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], SwitchComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwitchComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-switch',
      inputs: inputs$3,
      outputs: outputs$5,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SwitchComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the Switch component.
 */
class SwitchModule {}
SwitchModule.ɵfac = function SwitchModule_Factory(t) {
  return new (t || SwitchModule)();
};
SwitchModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SwitchModule
});
SwitchModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwitchModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SwitchComponent],
      exports: [SwitchComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Switch component with providers.
 */
class SwitchAllModule {}
SwitchAllModule.ɵfac = function SwitchAllModule_Factory(t) {
  return new (t || SwitchAllModule)();
};
SwitchAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SwitchAllModule
});
SwitchAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SwitchModule], SwitchModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwitchAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SwitchModule],
      exports: [SwitchModule],
      providers: []
    }]
  }], null, null);
})();
let input$1 = ['avatarIconCss', 'avatarText', 'cssClass', 'enabled', 'htmlAttributes', 'leadingIconCss', 'leadingIconUrl', 'text', 'trailingIconCss', 'trailingIconUrl', 'value'];
let outputs$4 = [];
/**
 * `e-chip` directive represent a chip of the Angular ChipList.
 * ```html
 * <ejs-chiplist >
 *   <e-chips>
 *    <e-chip text='chip1'></e-chip>
 *    <e-chip text='chip2'></e-chip>
 *   </e-chips>
 * </ejs-chiplist>
 * ```
 */
class ChipDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$1;
  }
}
ChipDirective.ɵfac = function ChipDirective_Factory(t) {
  return new (t || ChipDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ChipDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ChipDirective,
  selectors: [["e-chip"]],
  inputs: {
    avatarIconCss: "avatarIconCss",
    avatarText: "avatarText",
    cssClass: "cssClass",
    enabled: "enabled",
    htmlAttributes: "htmlAttributes",
    leadingIconCss: "leadingIconCss",
    leadingIconUrl: "leadingIconUrl",
    text: "text",
    trailingIconCss: "trailingIconCss",
    trailingIconUrl: "trailingIconUrl",
    value: "value"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipDirective, [{
    type: Directive,
    args: [{
      selector: 'e-chips>e-chip',
      inputs: input$1,
      outputs: outputs$4,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Chip Array Directive
 * @private
 */
class ChipsDirective extends ArrayBase {
  constructor() {
    super('chips');
  }
}
ChipsDirective.ɵfac = function ChipsDirective_Factory(t) {
  return new (t || ChipsDirective)();
};
ChipsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ChipsDirective,
  selectors: [["e-chips"]],
  contentQueries: function ChipsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ChipDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-chiplist>e-chips',
      queries: {
        children: new ContentChildren(ChipDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$2 = ['avatarIconCss', 'avatarText', 'chips', 'cssClass', 'enableDelete', 'enablePersistence', 'enableRtl', 'enabled', 'htmlAttributes', 'leadingIconCss', 'leadingIconUrl', 'locale', 'selectedChips', 'selection', 'text', 'trailingIconCss', 'trailingIconUrl'];
const outputs$3 = ['beforeClick', 'click', 'created', 'delete', 'deleted'];
const twoWays$2 = [''];
/**
 * Represents the Essential JS 2 Angular ChipList Component.
 * ```html
 * <ejs-chiplist></ejs-chiplist>
 * ```
 */
let ChipListComponent = class ChipListComponent extends ChipList {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['chips'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childChips;
    this.context.ngAfterContentChecked(this);
  }
};
ChipListComponent.ɵfac = function ChipListComponent_Factory(t) {
  return new (t || ChipListComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ChipListComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ChipListComponent,
  selectors: [["ejs-chiplist"]],
  contentQueries: function ChipListComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ChipsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childChips = _t.first);
    }
  },
  inputs: {
    avatarIconCss: "avatarIconCss",
    avatarText: "avatarText",
    chips: "chips",
    cssClass: "cssClass",
    enableDelete: "enableDelete",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    htmlAttributes: "htmlAttributes",
    leadingIconCss: "leadingIconCss",
    leadingIconUrl: "leadingIconUrl",
    locale: "locale",
    selectedChips: "selectedChips",
    selection: "selection",
    text: "text",
    trailingIconCss: "trailingIconCss",
    trailingIconUrl: "trailingIconUrl"
  },
  outputs: {
    beforeClick: "beforeClick",
    click: "click",
    created: "created",
    delete: "delete",
    deleted: "deleted"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ChipListComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
ChipListComponent = __decorate([ComponentMixins([ComponentBase])], ChipListComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipListComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-chiplist',
      inputs: inputs$2,
      outputs: outputs$3,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childChips: new ContentChild(ChipsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the ChipList component.
 */
class ChipListModule {}
ChipListModule.ɵfac = function ChipListModule_Factory(t) {
  return new (t || ChipListModule)();
};
ChipListModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ChipListModule
});
ChipListModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipListModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ChipListComponent, ChipDirective, ChipsDirective],
      exports: [ChipListComponent, ChipDirective, ChipsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the ChipList component with providers.
 */
class ChipListAllModule {}
ChipListAllModule.ɵfac = function ChipListAllModule_Factory(t) {
  return new (t || ChipListAllModule)();
};
ChipListAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ChipListAllModule
});
ChipListAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ChipListModule], ChipListModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipListAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ChipListModule],
      exports: [ChipListModule],
      providers: []
    }]
  }], null, null);
})();
const inputs$1 = ['content', 'cssClass', 'disabled', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'iconCss', 'iconPosition', 'isPrimary', 'isToggle', 'locale', 'position', 'target', 'visible'];
const outputs$2 = ['created'];
const twoWays$1 = [];
/**
 * Represents the Angular Fab Component.
 * ```html
 * <button ejs-fab content='fab'></button>
 * ```
 */
let FabComponent = class FabComponent extends Fab {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$2);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
FabComponent.ɵfac = function FabComponent_Factory(t) {
  return new (t || FabComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
FabComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FabComponent,
  selectors: [["", "ejs-fab", ""]],
  inputs: {
    content: "content",
    cssClass: "cssClass",
    disabled: "disabled",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    iconCss: "iconCss",
    iconPosition: "iconPosition",
    isPrimary: "isPrimary",
    isToggle: "isToggle",
    locale: "locale",
    position: "position",
    target: "target",
    visible: "visible"
  },
  outputs: {
    created: "created"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c2,
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function FabComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
FabComponent = __decorate([ComponentMixins([ComponentBase])], FabComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FabComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-fab]',
      inputs: inputs$1,
      outputs: outputs$2,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Fab component.
 */
class FabModule {}
FabModule.ɵfac = function FabModule_Factory(t) {
  return new (t || FabModule)();
};
FabModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FabModule
});
FabModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FabModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [FabComponent],
      exports: [FabComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Fab component with providers.
 */
class FabAllModule {}
FabAllModule.ɵfac = function FabAllModule_Factory(t) {
  return new (t || FabAllModule)();
};
FabAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FabAllModule
});
FabAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, FabModule], FabModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FabAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FabModule],
      exports: [FabModule],
      providers: []
    }]
  }], null, null);
})();
let input = ['disabled', 'iconCss', 'id', 'text', 'title'];
let outputs$1 = [];
/**
 * 'e-speeddialitem' directive represent a item of the Angular SpeedDial.
 * It must be contained in a SpeedDial component(`ejs-speeddial`).
 * ```html
 * <ejs-speeddial>
 *   <e-speeddialitems>
 *    <e-speeddialitem text='Cut'></e-speeddialitem>
 *    <e-speeddialitem text='Copy'></e-speeddialitem>
 *   </e-speeddialitems>
 * </ejs-speeddial>
 * ```
 */
class SpeedDialItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
SpeedDialItemDirective.ɵfac = function SpeedDialItemDirective_Factory(t) {
  return new (t || SpeedDialItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
SpeedDialItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SpeedDialItemDirective,
  selectors: [["e-speeddial-item"]],
  inputs: {
    disabled: "disabled",
    iconCss: "iconCss",
    id: "id",
    text: "text",
    title: "title"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpeedDialItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-speeddial-item',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * SpeedDialItem Array Directive
 * @private
 */
class SpeedDialItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
SpeedDialItemsDirective.ɵfac = function SpeedDialItemsDirective_Factory(t) {
  return new (t || SpeedDialItemsDirective)();
};
SpeedDialItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SpeedDialItemsDirective,
  selectors: [["e-speeddial-items"]],
  contentQueries: function SpeedDialItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, SpeedDialItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpeedDialItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'e-speeddial-items',
      queries: {
        children: new ContentChildren(SpeedDialItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['animation', 'closeIconCss', 'content', 'cssClass', 'direction', 'disabled', 'enablePersistence', 'enableRtl', 'iconPosition', 'isPrimary', 'itemTemplate', 'items', 'locale', 'modal', 'mode', 'openIconCss', 'opensOnHover', 'popupTemplate', 'position', 'radialSettings', 'target', 'visible'];
const outputs = ['beforeClose', 'beforeItemRender', 'beforeOpen', 'clicked', 'created', 'onClose', 'onOpen', 'visibleChange'];
const twoWays = ['visible'];
/**
 * Represents the Angular SpeedDial Component.
 * ```html
 * <button ejs-speeddial content='Edit'></button>
 * ```
 */
let SpeedDialComponent = class SpeedDialComponent extends SpeedDial {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.containerContext.ngAfterContentChecked(this);
  }
};
SpeedDialComponent.ɵfac = function SpeedDialComponent_Factory(t) {
  return new (t || SpeedDialComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
SpeedDialComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SpeedDialComponent,
  selectors: [["", "ejs-speeddial", ""]],
  contentQueries: function SpeedDialComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, SpeedDialItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.popupTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    animation: "animation",
    closeIconCss: "closeIconCss",
    content: "content",
    cssClass: "cssClass",
    direction: "direction",
    disabled: "disabled",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    iconPosition: "iconPosition",
    isPrimary: "isPrimary",
    itemTemplate: "itemTemplate",
    items: "items",
    locale: "locale",
    modal: "modal",
    mode: "mode",
    openIconCss: "openIconCss",
    opensOnHover: "opensOnHover",
    popupTemplate: "popupTemplate",
    position: "position",
    radialSettings: "radialSettings",
    target: "target",
    visible: "visible"
  },
  outputs: {
    beforeClose: "beforeClose",
    beforeItemRender: "beforeItemRender",
    beforeOpen: "beforeOpen",
    clicked: "clicked",
    created: "created",
    onClose: "onClose",
    onOpen: "onOpen",
    visibleChange: "visibleChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c5,
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function SpeedDialComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], SpeedDialComponent.prototype, "itemTemplate", void 0);
__decorate([Template()], SpeedDialComponent.prototype, "popupTemplate", void 0);
SpeedDialComponent = __decorate([ComponentMixins([ComponentBase])], SpeedDialComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpeedDialComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-speeddial]',
      inputs: inputs,
      outputs: outputs,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(SpeedDialItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    itemTemplate: [{
      type: ContentChild,
      args: ['itemTemplate']
    }],
    popupTemplate: [{
      type: ContentChild,
      args: ['popupTemplate']
    }]
  });
})();

/**
 * NgModule definition for the SpeedDial component.
 */
class SpeedDialModule {}
SpeedDialModule.ɵfac = function SpeedDialModule_Factory(t) {
  return new (t || SpeedDialModule)();
};
SpeedDialModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SpeedDialModule
});
SpeedDialModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpeedDialModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SpeedDialComponent, SpeedDialItemDirective, SpeedDialItemsDirective],
      exports: [SpeedDialComponent, SpeedDialItemDirective, SpeedDialItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the SpeedDial component with providers.
 */
class SpeedDialAllModule {}
SpeedDialAllModule.ɵfac = function SpeedDialAllModule_Factory(t) {
  return new (t || SpeedDialAllModule)();
};
SpeedDialAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SpeedDialAllModule
});
SpeedDialAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SpeedDialModule], SpeedDialModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpeedDialAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SpeedDialModule],
      exports: [SpeedDialModule],
      providers: []
    }]
  }], null, null);
})();

// Mapping root file for package generation

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonAllModule, ButtonComponent, ButtonModule, CheckBoxAllModule, CheckBoxComponent, CheckBoxModule, ChipDirective, ChipListAllModule, ChipListComponent, ChipListModule, ChipsDirective, FabAllModule, FabComponent, FabModule, RadioButtonAllModule, RadioButtonComponent, RadioButtonModule, SpeedDialAllModule, SpeedDialComponent, SpeedDialItemDirective, SpeedDialItemsDirective, SpeedDialModule, SwitchAllModule, SwitchComponent, SwitchModule };
