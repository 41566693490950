import { Directive, HostListener, Input } from '@angular/core';
import { FileItem, FileService } from '../../services/file.service';

@Directive({
  standalone: true,
  selector: '[solOpenInNewTab]',
})
export class OpenInNewTabDirective {
  constructor(private readonly fileService: FileService) {}
  @Input('solOpenInNewTab')
  public file?: FileItem;
  @Input('path') path?: string;
  @HostListener('click')
  public onClick() {
    if (this.file && this.path) {
      this.fileService.openFileInNewTab(this.file, this.path);
    }
  }
}
