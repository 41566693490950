<sol-loading-screen [isLoading]="loading$ | async">
  <form [formGroup]="form" (submit)="onSubmit()" class="centered-form">
    <fieldset class="fieldset">
      <header class="w-full text-center">
        <h1>Passwort ändern</h1>
      </header>
      <sol-textbox
        [required]="true"
        label="Altes Passwort"
        controlName="oldPassword"
        [validationMessages]="{
          pattern:
            'Zwischen 10 und 64 Zeichen, mindestens 1 Zahl, 1 Sonderzeichen, 1 Kleinbuchstaben und 1 Großbuchstaben',
        }"
      >
        <input
          required
          formFocus
          class="e-input"
          type="password"
          name="password"
          formControlName="oldPassword"
        />
      </sol-textbox>
      <sol-textbox
        [required]="true"
        label="Neues Passwort"
        controlName="password"
        [validationMessages]="{
          pattern:
            'Zwischen 10 und 64 Zeichen, mindestens 1 Zahl, 1 Sonderzeichen, 1 Kleinbuchstaben und 1 Großbuchstaben',
        }"
      >
        <input
          required
          formFocus
          class="e-input"
          type="password"
          autocomplete="off"
          name="password"
          formControlName="password"
        />
      </sol-textbox>
      <sol-textbox
        [required]="true"
        label="Passwort wiederholen"
        controlName="passwordRepeat"
        [validationMessages]="{
          matching: 'Passwörter müssen gleich sein.',
        }"
      >
        <input
          required
          class="e-input"
          type="password"
          autocomplete="off"
          name="passwordRepeat"
          formControlName="passwordRepeat"
        />
      </sol-textbox>
      <div class="my-3 flex gap-2">
        <button
          [disabled]="loading$ | async"
          ejs-button
          type="submit"
          name="button"
          class="icon-button"
        >
          <div>
            <i class="fa-regular fa-floppy-disk"></i>
            Speichern
          </div>
        </button>
      </div>
    </fieldset>
  </form>
</sol-loading-screen>
