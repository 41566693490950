<img
  *ngIf="fileId && userId; else placeholder"
  class="rounded-[50%] h-full w-full"
  [src]="
    fileId.toString()
      | imageAuth: '/files/':true:size | async
  "
/>
<ng-template #placeholder>
  <i class="fa-solid fa-user"></i>
</ng-template>
