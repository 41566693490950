import { Observable, tap } from 'rxjs';
import { BaseStoreService, Item } from '../services/base-store.service';
import {
  ActionEventArgs,
  GridComponent,
  PredicateModel,
} from '@syncfusion/ej2-angular-grids';
import { SfGridFilterService } from './sf-grid-filter.service';

export abstract class SfFilterGrid<TGetDto extends Item, TPutDto, TPostDto> {
  private STORAGE_FILTER_KEY: string = 'undefined';
  isLoading$: Observable<boolean>;
  items$: Observable<TGetDto[]>;
  filterModel: any;
  constructor(
    protected storage_item_key: string,
    protected filterService: SfGridFilterService,
    private store: BaseStoreService<TGetDto, TPutDto, TPostDto>,
  ) {
    this.setStorageKey(storage_item_key);
    this.isLoading$ = filterService.isLoading$;
    this.items$ = store.items$.pipe(
      tap((res) => {
        if (res.length == 0) {
          this.filterService.setLoading(true);
          this.filterService.setLoading(false);
        }
      }),
    );
  }
  public setStorageKey(prefix: string) {
    this.STORAGE_FILTER_KEY = prefix + '-sf-grid-filtering';
  }
  onCreated(grid: GridComponent) {
    this.filterService.onCreated(grid, this.STORAGE_FILTER_KEY);
  }
  onDataBound() {
    this.filterService.onDataBound();
  }
  onActionBegin(grid: GridComponent, params: ActionEventArgs) {
    this.filterService.onActionBegin(params as any);
  }
  onActionComplete(grid: GridComponent, params: ActionEventArgs) {
    if (params.requestType == 'filtering') {
      this.onFilterChanged(grid);
    }
    this.filterService.onActionComplete(grid, params as any);
  }
  onFilterChanged(grid: GridComponent) {
    this.store.setSuccess(false);
    var rowCount = (grid.getFilteredRecords() as any).length;
    if (rowCount <= 50) {
      this.search(grid);
    }
  }

  public setItemColumnFilter(grid: GridComponent, field: string, value: any) {
    if (grid) {
      if (value) {
        grid.clearFiltering([field]);
        grid.filterByColumn(field, 'equal', value);
      } else {
        grid.clearFiltering([field]);
        this.onFilterChanged(grid);
      }
    }
  }
  search(grid: GridComponent) {
    if (this.store && grid) {
      const filterModel = grid.filterSettings.columns?.map(
        (c: PredicateModel) => {
          return {
            field: c.field,
            operator: c.operator,
            value: c.value,
            matchCase: c.matchCase,
            ignoreAccent: c.ignoreAccent,
            predicate: c.predicate,
            actualFilterValue: c.actualFilterValue,
            actualOperator: c.actualOperator,
            type: c.type,
            ejpredicate: c.ejpredicate,
            uid: c.uid,
            isForeignKey: c.isForeignKey,
            condition: c.condition,
          } as PredicateModel;
        },
      );
      if (JSON.stringify(filterModel) !== JSON.stringify(this.filterModel)) {
        this.filterModel = filterModel;
        const filter = this.store?.getFilterPredicateModel(filterModel!);
        this.store?.setSearchFilter(filter);
      }
    }
  }
}
