import {
  Directive,
  EmbeddedViewRef,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subject, takeUntil, shareReplay } from 'rxjs';
import { UserPermissionsStoreService } from '../../services/user-permissions-store.service';
type CheckFn = (key: number) => Observable<boolean>;
interface ViewContext {
  $implicit: CheckFn;
}

@Directive({
  standalone: true,
  selector: '[checkPermissions]',
})
export class CheckPermissionsDirective implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  view: EmbeddedViewRef<ViewContext> | undefined;

  private permissionMemo: Record<string, Observable<boolean>> = {};

  static ngTemplateContextGuard(
    dir: CheckPermissionsDirective,
    ctx: unknown,
  ): ctx is ViewContext {
    return true;
  }

  constructor(
    private userPermissionsStore: UserPermissionsStoreService,
    @Optional() private tpl: TemplateRef<ViewContext>,
    private vcr: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.userPermissionsStore.userPermissions$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (!this.view) {
          this.view = this.vcr.createEmbeddedView(this.tpl, {
            $implicit: this.getCheckFn(),
          });
        }
      });
  }

  private getCheckFn(): CheckFn {
    return (key: number) =>
      this.memoizePermissionCheck(
        key,
        this.userPermissionsStore.checkPermissionAsync.bind(
          this.userPermissionsStore,
        ),
      );
  }

  private memoizePermissionCheck(
    key: number,
    checkFn: (key: number) => Observable<boolean>,
  ): Observable<boolean> {
    if (!this.permissionMemo[key]) {
      this.permissionMemo[key] = checkFn(key).pipe(shareReplay(1));
    }
    return this.permissionMemo[key];
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
