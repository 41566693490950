import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  ControlErrorsService,
  ValidationMessages,
} from '../../services/control-errors.service';
import { map, takeUntil } from 'rxjs/operators';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { formatString } from '../../jsHelper';
import { Subject } from 'rxjs';
import { ImageComponent } from '../image/image.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ImageComponent,
    ButtonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  selector: 'sol-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ControlErrorsService],
})
export class TextboxComponent implements OnInit, OnDestroy, AfterViewInit {
  private onDestroy$ = new Subject();
  @HostBinding('hidden')
  isHidden: boolean = false;
  @Output() iconClick = new EventEmitter<any>();
  @Input() icon: string = '';
  @Input() fontIcon: string = '';
  @Input() inputClasses: string = '';
  @Input() label?: string;
  @Input() labelTop?: boolean;
  @Input() required = false;
  @Input() hideIfEmpty = false;
  @Input() controlName?: string;
  @Input() validationMessages: ValidationMessages = {};
  @Input() enabled: boolean = true;
  @Input() linkFormat?: string;
  @Input() linkItems?: string[];
  @Input() form: FormGroup = new FormGroup({});
  link?: string;
  currentErrorMessages$ = this.controlErrorsService.currentErrorMessages$;
  errorExists$ = this.controlErrorsService.errors$.pipe(
    map((errors) => Object.keys(errors).length !== 0),
  );
  labelTop$: Observable<boolean>;
  disabled$: Observable<boolean>;
  changed$: Observable<boolean>;
  constructor(
    @Optional() private controlContainer: ControlContainer,
    public controlErrorsService: ControlErrorsService,
  ) {
    this.labelTop$ = controlErrorsService.labelTop$;
    this.disabled$ = controlErrorsService.disabled$;
    this.changed$ = controlErrorsService.changed$;
  }
  ngAfterViewInit(): void {
    this.setFields();
  }
  public ngOnInit(): void {
    // this.setFields();
  }
  setFields() {
    if (this.controlName && this.controlContainer) {
      this.controlErrorsService.setControl(
        this.controlName,
        this.controlContainer,
      );
      this.controlErrorsService.setValidationMessages(this.validationMessages);
    }
    if (this.linkItems && this.linkFormat && this.form) {
      this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((v) => {
        const value = this.form.getRawValue();
        let linkItemValues: string[] = [];
        this.linkItems?.forEach((item) => {
          linkItemValues = [...linkItemValues, value[item] ?? ''];
        });
        this.link = formatString(this.linkFormat!, linkItemValues);
      });
    }
    if (this.hideIfEmpty) {
      this.isHidden = true;
      this.controlErrorsService.valueChanged$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((value) => {
          if (this.hideIfEmpty && this.controlName) {
            this.isHidden = !value;
          }
        });
    }
  }
  onButtonClick($event: any) {
    this.iconClick.emit($event);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
