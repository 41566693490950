<sol-loading-screen [opacity]="1" class="content-wrapper-height">
  <ng-container *transloco="let t">
    <ejs-grid
      #grid
      [dataSource]="allItems$ | async"
      height="100%"
      allowFiltering="true"
      allowPaging="true"
      allowSorting="true"
      allowReordering="true"
      allowResizing="true"
      [resizeSettings]="{ mode: 'Auto' }"
      [gridLines]="'Both'"
      [rowHeight]="30"
      (actionComplete)="filterService.onActionComplete(grid, $event)"
      (actionBegin)="filterService.onActionBegin($event)"
      (created)="filterService.onCreated(grid, 'account')"
      (dataBound)="filterService.onDataBound()"
      [showColumnChooser]="true"
    >
      <ng-template #toolbarTemplate let-data>
        <sol-grid-toolbar
          [grid]="grid"
          (refreshClicked)="onRefreshClicked()"
          title="Benutzer"
          [newLink]="'/user'"
          [createPermission]="createPermission"
        ></sol-grid-toolbar>
      </ng-template>
      <e-columns>
        <e-column field="id" headerText="ID" type="number">
          <ng-template #template let-data>
            <div>
              <a [routerLink]="'/user/' + data.id">{{ data.id }}</a>
            </div>
          </ng-template>
        </e-column>
        <e-column
          field="userName"
          headerText="Benutzername"
          type="string"
        ></e-column>
        <e-column
          field="firstName"
          headerText="Vorname"
          type="string"
        ></e-column>
        <e-column
          field="lastName"
          headerText="Nachname"
          type="string"
        ></e-column>
        <e-column
          field="userStatusId"
          headerText="Status"
          type="number"
          [filter]="{
            type: 'CheckBox',
          }"
        >
          <ng-template #filterItemTemplate let-data>
            <div>
              {{ t("Account.Status." + US[data.id]) }}
            </div>
          </ng-template>
          <ng-template #template let-data>
            <div>
              {{ t("Account.Status." + US[data.userStatusId]) }}
            </div>
          </ng-template>
        </e-column>
        <e-column
          *ngIf="(currentAuthUser$ | async)?.isSystemUser"
          field="organization"
          headerText="Organisation"
          type="string"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
</sol-loading-screen>
