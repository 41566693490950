import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ControlContainer, ValidationErrors } from '@angular/forms';

import {
  ControlErrorsService,
  ValidationMessages,
} from '../../services/control-errors.service';
import { Observable } from 'rxjs/internal/Observable';
import { CommonModule } from '@angular/common';

interface TemplateContext {
  $implicit: ValidationErrors;
}

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'sol-control-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ControlErrorsService],
})
export class ControlErrorsComponent implements OnInit {
  @Input() validationMessages: ValidationMessages = {};
  @Input() controlName?: string;

  @ContentChild(TemplateRef) template: TemplateRef<TemplateContext> | null =
    null;

  templateContext: TemplateContext = {
    $implicit: {},
  };
  currentErrorMessages$: Observable<string[]> =
    this.controlErrorsService.currentErrorMessages$;
  constructor(
    private controlErrorsService: ControlErrorsService,
    private controlContainer: ControlContainer,
  ) {}

  public ngOnInit(): void {
    if (this.controlName) {
      this.controlErrorsService.setControl(
        this.controlName,
        this.controlContainer,
      );
      this.controlErrorsService.setValidationMessages(this.validationMessages);
      this.templateContext = {
        $implicit: { errors$: this.controlErrorsService.errors$ },
      };
    }
  }
}
