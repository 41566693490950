import { KeyValue, KeyValuePipe, SlicePipe } from '@angular/common';
import { KeyValueDiffers, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'keyvalueEnum',
})
export class KeyValueEnumPipe implements PipeTransform {
  keyValuePipe = new KeyValuePipe(this.differs);
  slicePipe = new SlicePipe();
  constructor(private readonly differs: KeyValueDiffers) {}

  transform<K extends string, V>(
    value: Record<K, V> | ReadonlyMap<K, V>,
    compareFn?:
      | ((
          a: KeyValue<unknown, unknown>,
          b: KeyValue<unknown, unknown>,
        ) => number)
      | undefined,
    langPath?: string,
  ): KeyValue<unknown, unknown>[] {
    const tValues = this.keyValuePipe.transform(value, compareFn);
    return this.slicePipe.transform(tValues, tValues.length / 2);
  }
}
