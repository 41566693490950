<div class="wrapper">
    <div class="overlay" *ngIf="isLoading">
      <div class="spinner-wrapper" [ngStyle]="{ opacity: opacity }">
        <sol-spinner></sol-spinner>
      </div>
    </div>
  
    <div class="loaded-content" [class.blurred]="isLoading">
      <ng-content></ng-content>
    </div>
  </div>
  