import { Inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AUTHENTICATION_DEFAULT_OPTIONS } from '../Tokens/tokens';
import { AuthenticationConfig } from '../authentication-config';
import { AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { ChangePasswordDto } from '../../dto/change-password-dto';
import { LoginResultDto } from '../../dto/login-result-dto';
import { Observable } from 'rxjs';
import { AuthUserPutDto } from '../../dto/auth-user-put-dto';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  private serviceUrl: string;
  constructor(
    @Inject(AUTHENTICATION_DEFAULT_OPTIONS)
    authConfig: AuthenticationConfig,
    private http: HttpClient,
  ) {
    this.serviceUrl = authConfig.apiBaseUrl + 'authuser';
  }

  getCurrentAuthUser(): Observable<AuthUserGetDto> {
    return this.http.get<AuthUserGetDto>(this.serviceUrl);
  }
  updateCurrentUser(userPutDto: AuthUserPutDto) {
    return this.http.put(this.serviceUrl, userPutDto);
  }
  changePassword(changePasswordDto: ChangePasswordDto) {
    return this.http.post<LoginResultDto>(
      this.serviceUrl + '/password',
      changePasswordDto,
    );
  }
  logoutAllDevices() {
    return this.http.post(this.serviceUrl + '/logout-all', {});
  }
}
