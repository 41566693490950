import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { GlobalErrorService } from '../global-error.service';
import { HttpContextToken } from '@angular/common/http';
import { GlobalLoadingBarService } from './global-loading-bar.service';
export enum MessageTypes {
  Info,
  Error,
}
export interface Message {
  text: string;
  type: MessageTypes;
}
@Injectable({
  providedIn: 'root',
})
export class ComponentStateService implements OnDestroy {
  private onDestroy$ = new Subject();
  private _infos$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    [],
  );
  private _errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    [],
  );
  loadingCounter = 0;
  infos$: Observable<string[]> = this._infos$.asObservable();
  errors$: Observable<string[]> = this._errors$.asObservable();
  messages$: Observable<Message[]> = combineLatest([
    this.infos$,
    this.errors$,
  ]).pipe(
    map(([infos, errors]) => [
      ...infos.map((i) => {
        return { text: i, type: MessageTypes.Info } as Message;
      }),
      ...errors.map((e) => {
        return { text: e, type: MessageTypes.Error } as Message;
      }),
    ]),
  );
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean> = this._loading$.asObservable();
  private _retry$: Subject<boolean> = new Subject();
  constructor(
    private globalErrorService: GlobalErrorService,
    private globalLoadingService: GlobalLoadingBarService,
  ) {
    globalErrorService.retrySend
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.retry());
    globalErrorService.retryDismissed
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.dismiss());
  }

  setInfos(infos: string[]) {
    if (infos) this._infos$.next(infos);
  }
  addInfo(info: string) {
    if (info) this._infos$.next([...this._infos$.value, info]);
  }
  setErrors(errors: string[]) {
    if (errors) this._errors$.next(errors);
  }
  addError(error: string) {
    if (error) this._errors$.next([...this._errors$.value, error]);
  }
  setLoading(loading: boolean) {
    this.globalLoadingService.setLoading(loading);
    if (loading) {
      this.loadingCounter++;
    } else {
      this.loadingCounter--;
    }
    if (this.loadingCounter <= 0) {
      this._loading$.next(false);
    } else this._loading$.next(true);
  }
  retry() {
    this._retry$.next(true);
  }
  dismiss() {
    this._retry$.next(false);
  }
  // retryOnError<T>(source: Observable<T>): Observable<T> {
  //   this.setLoading(true);
  //   return source.pipe(
  //     finalize(() => this.setLoading(false)),
  //     catchError((error) => {
  //       this.setLoading(false);
  //       if (error.status === 409) {
  //         return this._retry$.pipe(
  //           takeUntil(this.onDestroy$),
  //           take(1),
  //           switchMap((retry: boolean) =>
  //             retry ? this.retryOnError(source) : throwError(() => error),
  //           ),
  //         );
  //       }
  //       return throwError(() => error);
  //     }),
  //   );
  // }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
