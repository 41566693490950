import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[hold]',
})
export class HoldButtonDirective {
  @HostBinding('class') get classes(): string {
    return 'hold-button';
  }
  @HostListener('mouseup')
  public mouseup() {
    if (this.element.classList.contains('hold-button-success')) {
      this.clickEmitter.emit();
    }
    this.show(false);
  }
  @HostListener('mouseleave')
  public mouseleave() {
    this.show(false);
  }
  @HostListener('mousedown')
  public mousedown() {
    this.show(true);
  }
  @Output('hold') public clickEmitter = new EventEmitter();
  element: HTMLSpanElement;
  constructor(private el: ElementRef<HTMLButtonElement>) {
    const button = this.el.nativeElement;
    const fill = document.createElement('span');
    fill.classList.add('hold-button-fill');
    button.prepend(fill);
    this.element = fill;
    this.element.addEventListener('animationend', () => {
      this.element.classList.add('hold-button-success');
    });
  }
  startDate: Date | undefined;
  show(show: boolean) {
    if (show) {
      this.element.classList.add('hold-button-grow');
    } else {
      this.element.classList.remove('hold-button-grow', 'hold-button-success');
    }
  }
}
