<div>
  <ejs-appbar>
    <button
      ejs-button
      cssClass="e-inherit menu"
      iconCss="e-icons e-menu"
      (click)="onMenuClick()"
    ></button>
    <div class="flex gap-2">
      <sol-image
        icon="location_GPS.svg"
        class="svg-icon-gradient h-6 w-6"
      ></sol-image>
      <a [routerLink]="'/'"
        ><span class="regular hidden sm:block">{{ barTitle }}</span></a
      >
    </div>
    <div class="e-appbar-spacer">
      <ng-content></ng-content>
    </div>
    <div
      class="mr-3 w-fit text-base"
      *ngIf="!(isMobile$ | async) && displayCurrentTime"
    >
      <div style="font-weight: bold; height: 25px">
        {{ currentDatetime$ | async | date: "shortTime" }}
      </div>
      <div style="line-height: normal; opacity: 0.7; font-size: 13px">
        {{ currentDatetime$ | async | date }}
      </div>
    </div>
    <button
      Class="btn-gradient hidden sm:block"
      [routerLink]="'/logout'"
      *ngIf="showLogout$ | async"
    >
      Abmelden
    </button>
  </ejs-appbar>
</div>
