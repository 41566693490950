<ng-container *ngIf="user">
  <ng-container *ngIf="(currentUser$ | async)?.hasUserWritePermission"
    ><div class="my-3 flex flex-wrap gap-2">
      <button
        [disabled]="loading$ | async"
        ejs-button
        type="button"
        name="button"
        class="icon-button"
        (click)="newPasswordDialog.visible = true"
      >
        Passwort neu vergeben
      </button>
    </div>
  </ng-container>
</ng-container>
<ejs-dialog
  #newPasswordDialog
  [showCloseIcon]="true"
  header="Neues Passwort vergeben"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="newPasswordDialog.hide()"
>
  <ng-template #content>
    <ng-container *ngIf="user">
      <form
        [formGroup]="newPasswordForm"
        (submit)="onNewPasswordSubmit(user.id, newPasswordDialog)"
      >
        <fieldset class="flex flex-wrap gap-2 border-none">
          <sol-textbox
            [required]="true"
            label="Passwort"
            controlName="password"
            [validationMessages]="{
              pattern:
                'Zwischen 10 und 64 Zeichen, mindestens 1 Zahl, 1 Sonderzeichen, 1 Kleinbuchstaben und 1 Großbuchstaben',
            }"
          >
            <input
              formFocus
              required
              class="e-input"
              type="password"
              name="password"
              formControlName="password"
              autocomplete="new-password"
            />
          </sol-textbox>
          <div class="my-3 flex gap-2">
            <button
              [disabled]="loading$ | async"
              ejs-button
              type="submit"
              name="button"
              class="icon-button"
            >
              <div>
                <i class="fa-regular fa-floppy-disk"></i>
                Speichern
              </div>
            </button>
          </div>
        </fieldset>
      </form>
    </ng-container>
  </ng-template>
</ejs-dialog>
