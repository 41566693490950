import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function BiggerThanValidator(keys: string[]): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const { controls } = group as FormGroup;
    if (
      !controls[keys[0]] ||
      !controls[keys[0]].value ||
      !controls[keys[1]] ||
      !controls[keys[1]].value
    ) {
      return null;
    }
    if (
      new Date(controls[keys[0]].value).valueOf() >
      new Date(controls[keys[1]].value).valueOf()
    ) {
      return null;
    }
    return { biggerThan: 'error' };
  };
}
