import { ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { Item } from './services/base-store.service';

export function updateItemInList<T extends Item>(item: T, items: T[]) {
  return items.map((t) => (t.id == item.id ? { ...t, ...item } : t));
}
export function addChildren<T extends Item>(item: T, items: T[]) {
  return [...items, item];
}
export function addOrUpdate<T extends Item>(item: T, items: T[]) {
  const oldItem = items.find((l) => l.id == item.id);
  if (!oldItem) {
    return addChildren<T>(item, items);
  } else {
    return updateItemInList<T>(item, items);
  }
}
export function showSuccessToast(content?: string | undefined) {
  ToastUtility.show({
    content: content ?? 'Erfolgreich gespeichert',
    timeOut: 2000,
    position: { X: 'Center', Y: 'Bottom' },
    cssClass: 'success-toast',
  });
}
export function showErrorToast(content?: string | undefined) {
  ToastUtility.show({
    content: content,
    timeOut: 6000,
    position: { X: 'Center', Y: 'Bottom' },
    cssClass: 'error-toast',
  });
}
