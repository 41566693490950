import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonSolutionConfig } from '../common-solution-config';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../Tokens/tokens';
// export interface MenuCategory {
//   text: string;
//   iconText?: string;
//   children?: MenuLink[];
//   expanded?: boolean;
//   anonymous: boolean,
// }
export interface MenuLink {
  permissions?: number[];
  link?: string;
  text: string;
  primary?: boolean;
  iconText?: string;
  divider?: boolean;
  children?: MenuLink[];
  anonymous?: boolean;
  anonymousOnly?: boolean;
  expanded?: boolean;
  selectable?: boolean;
  external?: boolean;
  click?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class MenuStoreService {
  private _menuItems$ = new BehaviorSubject<MenuLink[]>([]);
  menuItems$ = this._menuItems$.asObservable();
  constructor(
    @Inject(COMMON_SOLUTION_DEFAULT_OPTIONS) config: CommonSolutionConfig,
  ) {
    this.setMenuItems(config.menuItems);
  }
  setMenuItems(items: MenuLink[]) {
    this._menuItems$.next(items);
  }
}
