import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../../../Tokens/tokens';
import { CommonSolutionConfig } from '../../../common-solution-config';
import {
  FileItem,
  FileService,
  FileTypes,
} from '../../../services/file.service';
import { OpenInNewTabDirective } from '../../directives/open-in-new-tab.directive';
import { CommonModule } from '@angular/common';
import { ImageAuthPipe } from '../../image-auth/image-auth.pipe';
import { DownloadFileDirective } from '../../directives/download-file.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ImageDisplayComponent,
    ImageAuthPipe,
    OpenInNewTabDirective,
    DownloadFileDirective,
  ],
  selector: 'sol-image-display',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageDisplayComponent implements OnChanges {
  FileTypes = FileTypes;
  @Input() public file?: FileItem;
  @Input() alt = '';
  @Input() path = '';
  @Input() thumbnail = true;
  @Input() size = 128;
  src: string = 'assets/img/placeholder-image.png';
  iconText?: string;
  fileType?: FileTypes;
  constructor(
    @Inject(COMMON_SOLUTION_DEFAULT_OPTIONS) config: CommonSolutionConfig,
    private cdr: ChangeDetectorRef,
  ) {
    this.path = config.iconPath;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['file']) {
      if (this.file) {
        const extension = '.' + this.file.name.split('.').pop();
        if (extension) {
          const fileType = FileService.fileTypes.find(
            (x) => x.fileExtension === extension.toLowerCase(),
          );
          if (fileType) {
            this.fileType = fileType.type;
            switch (fileType.type) {
              case FileTypes.IMAGE:
                break;
              case FileTypes.PDF:
                this.iconText = 'picture_as_pdf';
                break;
              case FileTypes.VIDEO:
                this.iconText = 'video_library';
                break;
              case FileTypes.AUDIO:
                this.iconText = 'audiotrack';
                break;
              case FileTypes.TEXT:
                this.fileType = FileTypes.UNKNOWN;
                break;
              default:
                this.fileType = FileTypes.UNKNOWN;
                break;
            }
          } else {
            this.fileType = FileTypes.UNKNOWN;
          }
        }
      }
      this.cdr.detectChanges();
    }
  }
}
