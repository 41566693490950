import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  GridToolbarComponent,
  LoadingScreenComponent,
  SfGridFilterService,
} from 'ngx-common-solution';
import {
  Subject,
  Observable,
  BehaviorSubject,
  timeout,
  of,
  mergeMap,
  forkJoin,
  switchMap,
  map,
  tap,
  take,
} from 'rxjs';
import { AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { EnumUserStatuses } from '../../dto/enum-user-statuses';
import { AdminService } from '../services/admin.service';
import { AuthenticationStoreService } from '../stores/authentication-store.service';
import {
  FilterService,
  GridModule,
  PageService,
  SortService,
  ResizeService,
} from '@syncfusion/ej2-angular-grids';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    GridModule,
    RouterModule,
    LoadingScreenComponent,
    TranslocoModule,
    GridToolbarComponent,
  ],
  selector: 'auth-account-grid',
  templateUrl: './account-grid.component.html',
  styleUrls: ['./account-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SfGridFilterService,
    FilterService,
    PageService,
    SortService,
    ResizeService,
  ],
})
export class AccountGridComponent implements OnDestroy {
  private onDestroy$ = new Subject();
  US = EnumUserStatuses;
  allItems$: Observable<any>;
  private _items$ = new BehaviorSubject<AuthUserGetDto[]>([]);
  currentAuthUser$: Observable<AuthUserGetDto | null>;
  @Input() createPermission?: any;
  constructor(
    public filterService: SfGridFilterService,
    private adminService: AdminService,
    authStore: AuthenticationStoreService,
    transloco: TranslocoService,
  ) {
    this.refreshUsers();

    const items = Object.keys(EnumUserStatuses)
      .map((v) => {
        return { id: Number(v) };
      })
      .filter((n) => n.id);
    const translationObservables = items.map((item) =>
      transloco
        .selectTranslate<string>(`Account.Status.${this.US[item.id]}`)
        .pipe(
          map((translation) => ({ ...item, name: translation })),
          take(1),
        ),
    );
    forkJoin(translationObservables).subscribe((items) => {
      this.filterService.addColumnConfiguration<any>({
        columnName: 'userStatusId',
        items: items,
        withBlank: false,
        foreignKeyValue: 'name',
      });
    });
    this.allItems$ = this._items$;
    this.currentAuthUser$ = authStore.authUser$;
  }
  refreshUsers() {
    this.adminService
      .getAllUsers()
      .subscribe((users) => this._items$.next(users));
  }
  onRefreshClicked() {
    this.refreshUsers();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
