import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
} from 'rxjs';
import { CommonSolutionConfig } from '../common-solution-config';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../Tokens/tokens';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsStoreService implements OnDestroy {
  private onDestroy$ = new Subject();
  private _userPermissions$: ReplaySubject<number[]> = new ReplaySubject<
    number[]
  >(1);
  userPermissions$: Observable<number[]> = this._userPermissions$.pipe(
    shareReplay(1),
  );

  Permissions = this.config.Permissions;
  constructor(
    @Inject(COMMON_SOLUTION_DEFAULT_OPTIONS)
    private config: CommonSolutionConfig,
  ) {
    if (!config.Permissions) {
      console.error('Permissions enum not set');
    }
  }

  /**
   * Sets new user permissions stored in this service.
   */
  setUserPermissions(permissions: number[]) {
    this._userPermissions$.next(permissions);
  }
  /**
   * Checks if permission array contains at least one permission of another permission array
   */
  checkOrPermissions(
    permissions: number[],
    userPermissions: number[] = [],
  ): boolean {
    var hasPermission = false;
    if (permissions) {
      permissions.every((permission) => {
        if (this.checkPermission(permission, userPermissions)) {
          hasPermission = true;
          return false;
        }
        return true;
      });
    }
    return hasPermission;
  }
  /**
   * Checks if permission array contains a permission
   */
  private checkPermission(
    permission: number,
    userPermissions: number[] = [],
  ): boolean {
    if (userPermissions.length > 0) {
      const r = !!userPermissions.find((p) => p == permission);
      return r;
    }
    if (!!userPermissions.find((p) => p == permission)) {
      return true;
    }
    return false;
  }
  /**
   * Checks if user permissions contain at least one permission
   */
  checkOrPermissionsAsync(permissions: number[]): Observable<boolean> {
    return this.userPermissions$.pipe(
      map((ps) => this.checkOrPermissions(permissions, ps)),
    );
  }
  /**
   * Checks if user permissions contain a permission
   */
  checkPermissionAsync(permission: number): Observable<boolean> {
    return this.userPermissions$.pipe(
      map((ps) => this.checkPermission(permission, ps)),
    );
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
