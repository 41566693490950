import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ImageComponent } from '../image/image.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, ButtonModule, ImageComponent],
  selector: 'sol-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  @Input() text: string = '';
  @Input() icon: string = '';
  @Input() buttonSize: number = 96;
  @Input() iconSize: number = 36;
}
