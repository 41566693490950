import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import { Router } from '@angular/router';
import { isNullUndefinedOrWhitespace } from 'ngx-common-solution';
import { AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { EnumUserStatuses } from '../../dto/enum-user-statuses';
import { LoginRequestDto } from '../../dto/login-request-dto';
import { LoginResultDto } from '../../dto/login-result-dto';
import { LoginStatus } from '../../dto/login-status';
import { AuthUserService } from '../services/auth-user.service';
import { AuthenticationService } from '../services/authentication.service';
import { AuthenticationStatusService } from './authentication-status.service';
import { TokenStoreService } from './token-store.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStoreService {
  isAuthenticated$: Observable<boolean>;

  private _authUser$ = new BehaviorSubject<AuthUserGetDto | null>(null);
  authUser$ = this._authUser$.pipe(shareReplay(1));

  authFlowInProgress$: Observable<boolean>;
  constructor(
    private authenticationStatusService: AuthenticationStatusService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private tokenStore: TokenStoreService,
    private authUserService: AuthUserService,
  ) {
    // this.checkToken('').subscribe();
    this.isAuthenticated$ = this.authenticationStatusService.isAuthenticated$;
    this.isAuthenticated$
      .pipe(
        switchMap((isAuth) =>
          isAuth ? authUserService.getCurrentAuthUser() : of(null),
        ),
      )
      .subscribe((user) => this.setCurrentAuthUser(user));
    const userCredentialsMissing$ = this.authUser$.pipe(
      map((user) => {
        if (user == null) return false;
        return (
          isNullUndefinedOrWhitespace(user?.firstName) ||
          isNullUndefinedOrWhitespace(user?.lastName)
        );
      }),
    );
    const newPasswordRequired$ = this.authUser$.pipe(
      map((user) => {
        if (user == null) return false;
        return user.userStatusId == EnumUserStatuses.NewPasswordRequired;
      }),
    );
    const checkAuthFlow$ = combineLatest([
      newPasswordRequired$,
      userCredentialsMissing$,
    ]).pipe(
      tap(([newPasswordRequired, credMissing]) => {
        if (newPasswordRequired) {
          this.router.navigate(['/account/change-password/']);
        } else if (credMissing) {
          this.router.navigate(['/account/fill-credentials']);
        }
      }),
    );
    this.authFlowInProgress$ = checkAuthFlow$.pipe(
      map(([newPasswordReq, credMissing]) => newPasswordReq || credMissing),
    );
    checkAuthFlow$.subscribe();

    // this.authCode$.subscribe((code) => {
    //   if (code) {
    //     const clientString = sessionStorage.getItem('client');
    //     if (clientString) {
    //       const client = JSON.parse(clientString) as OAuthClientDto;
    //       this.loginMSAL(client);
    //       this.oauthService.code
    //     }
    //   }
    // });
    // const idToken = sessionStorage.getItem('id_token');
    // if (idToken) {
    //   this.saveLoginDataOnSuccess({
    //     token: idToken,
    //     refreshToken: idToken,
    //     status: LoginStatus.Success,
    //   });
    //   this._isAuthenticated$.next(true);
    // }
    this.isAuthenticated$.subscribe((isAuth) => {
      if (!isAuth) {
        const idToken = sessionStorage.getItem('id_token');
        if (idToken) {
          this.tokenStore.saveLoginDataOnSuccess({
            token: idToken,
            refreshToken: undefined,
            status: LoginStatus.Success,
          });
          this.authenticationStatusService.setAuthenticated(true);
        }
      }
    });
  }
  setCurrentAuthUser(user: AuthUserGetDto | null) {
    this._authUser$.next(user);
  }
  refreshCurrentAuthUser() {
    return this.authUserService
      .getCurrentAuthUser()
      .pipe(tap((user) => this.setCurrentAuthUser(user)));
  }

  loginUser = (cred: LoginRequestDto): Observable<LoginResultDto> => {
    return this.authenticationService.login(cred).pipe(
      tap((res) => {
        localStorage.setItem('loginclient', 'solution');
        if (res.status == LoginStatus.Success && res.token) {
          this.tokenStore.saveLoginDataOnSuccess(res);
        } else if (res.status == LoginStatus.NewPasswordRequired && res.token) {
          this.tokenStore.saveLoginDataOnSuccess(res);
        }
      }),
    );
  };
}
