import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
} from '@angular/core';
import {
  ColumnModel,
  ExcelExportProperties,
  GridComponent,
  PdfExportProperties,
} from '@syncfusion/ej2-angular-grids';
import { SfGridFilterService } from '../sf-grid-filter.service';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../../Tokens/tokens';
import { CommonSolutionConfig } from '../../common-solution-config';
import { CommonModule, formatDate } from '@angular/common';
import { CheckPermissionsDirective } from '../directives/check-permissions.directive';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CheckPermissionsDirective,
    RouterModule,
    ButtonModule,
  ],
  selector: 'sol-grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
  styleUrl: './grid-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridToolbarComponent implements OnInit {
  Permissions = this.config.Permissions;
  @Input() grid?: GridComponent;
  @Input() title?: string;
  @Input() newLink?: string;
  @Input() createPermission?: any;
  @Output() refreshClicked = new EventEmitter();
  @Output() createClicked = new EventEmitter();
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(COMMON_SOLUTION_DEFAULT_OPTIONS)
    private config: CommonSolutionConfig,
    private filterService: SfGridFilterService,
  ) {}
  ngOnInit(): void {
    if (this.grid) {
      this.grid.allowExcelExport = true;
      this.grid.allowPdfExport = true;
      this.grid.allowSelection = true;
      this.grid.selectionSettings = {
        type: 'Multiple',
        persistSelection: true,
      };
      const primaryColumn = this.grid.columns.find((c: any) => c.field == 'id');
      if (primaryColumn) (primaryColumn as ColumnModel).isPrimaryKey = true;
    }
  }

  exportExcel() {
    const selectedRecords = this.grid?.getSelectedRecords() ?? [];
    const date = formatDate(new Date(), 'yyyy-MM-dd_HH_mm', this.locale);
    let exportProperties: ExcelExportProperties | undefined = {
      fileName: 'Export_' + date + '.xlsx',
    };
    if (selectedRecords.length > 0) {
      exportProperties.dataSource = selectedRecords;
    }
    this.grid?.excelExport(exportProperties);
  }
  exportPdf() {
    const selectedRecords = this.grid?.getSelectedRecords() ?? [];
    let exportProperties: PdfExportProperties | undefined = undefined;
    if (selectedRecords.length > 0) {
      exportProperties = {
        dataSource: selectedRecords,
        allowHorizontalOverflow: true,
      };
    }
    this.grid?.pdfExport(exportProperties);
  }
  print() {
    this.grid?.print();
  }
  resetFilter() {
    this.filterService.resetState();
  }
  showColumnChooser() {
    this.grid?.columnChooserModule?.openColumnChooser(0, 40); // give X and Y axis
  }
  refresh() {
    this.refreshClicked.emit();
  }
  onCreateClick() {
    this.createClicked.emit();
  }
}
