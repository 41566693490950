import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, forkJoin, map, mergeMap, of } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'enumTranslator',
})
export class EnumTranslatorPipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}
  transform(
    value: KeyValue<unknown, unknown>[],
    langPath: string,
  ): Observable<KeyValue<unknown, unknown>[]> {
    return of(value).pipe(
      mergeMap((valuePairs) => {
        return forkJoin(
          valuePairs.map((keyValuePair) =>
            this.transloco
              .selectTranslate(
                langPath +
                  '.' +
                  (keyValuePair.key == undefined
                    ? 'Undefined'
                    : keyValuePair.key),
                {},
                this.transloco.getActiveLang(),
              )
              .pipe(
                map((statusName) => ({
                  key: statusName,
                  value: keyValuePair.value,
                })),
              ),
          ),
        );
      }),
    );
  }
}
