<sol-loading-screen>
  <div class="e-float-input e-input-group" *ngIf="withSearch">
    <input class="e-input" type="search" [formControl]="searchControl" />
    <span class="e-float-line"></span> <label class="e-float-text">Suche</label>
  </div>
  <div class="my-4 max-h-80 overflow-auto shadow-sm">
    <ejs-grid
      *ifPermission="selectorConfig.permission"
      [dataSource]="filteredItems$ | async"
      [allowSorting]="true"
      (rowSelected)="onItemSelect($event)"
      [selectionSettings]="{ mode: 'Row', type: 'Single' }"
      [title]="selectorConfig.title"
    >
      <e-columns>
        <e-column
          *ngFor="let colDef of selectorConfig?.columnDefs"
          [field]="colDef.searchPropertyName"
          [headerText]="colDef.headerText"
          [allowSorting]="true"
        >
          <ng-template #template let-data>
            {{ colDef?.valueFormatter(data) }}
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</sol-loading-screen>
