<button
  ejs-button
  type="button"
  class="icon-button"
  [ngStyle]="{ height: buttonSize + 'px', width: buttonSize + 'px' }"
>
  <sol-image
    class="icon-img m-auto"
    [ngStyle]="{ height: iconSize + 'px', 'min-width': iconSize + 'px' }"
    [icon]="icon"
    *ngIf="icon"
  />
  {{ text }}
</button>
