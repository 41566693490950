import {
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ERROR_IMAGE_PATH, LOADING_IMAGE_PATH } from './image-auth.injector';
import { CommonSolutionConfig } from '../../common-solution-config';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../../Tokens/tokens';

// {
//   provide: LOADING_IMAGE_PATH,
//   useValue: config.loadingImagePath || null,
// },
// {
//   provide: ERROR_IMAGE_PATH,
//   useValue: config.errorImagePath || null,
// },
@Pipe({
  standalone: true,
  name: 'imageAuth',
})
export class ImageAuthPipe implements PipeTransform, OnDestroy {
  private onDestroy$ = new Subject();
  private loadingImagePath!: string;
  private errorImagePath!: string;
  private latestValue?: SafeUrl;
  private latestBlobUrl?: string;
  private transformValue = new BehaviorSubject<string>('');
  private transformedValue$: Observable<string | SafeUrl>;
  constructor(
    @Inject(COMMON_SOLUTION_DEFAULT_OPTIONS)
    private config: CommonSolutionConfig,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(LOADING_IMAGE_PATH)
    private defaultLoadingImagePath: string,
    @Inject(ERROR_IMAGE_PATH) private defaultErrorImagePath: string,
  ) {
    this.transformedValue$ = this.setUpObservable();
  }

  transform(
    fileId: any,
    path: string,
    thumbnail: boolean = false,
    size?: number,
    loadingImagePath?: string,
    errorImagePath?: string,
  ): Observable<string | SafeUrl> {
    this.setLoadingAndErrorImagePaths(loadingImagePath, errorImagePath);
    if (!path || !fileId) {
      return of(this.errorImagePath);
    }
    const imagePath =
      this.config.apiBaseUrl +
      path +
      fileId?.toString() +
      (thumbnail ? '/thumbnail' : '') +
      (size && size > 0 ? '?x=' + size + '&y=' + size : '');

    this.transformValue.next(imagePath);
    return this.transformedValue$.pipe(
      startWith(this.loadingImagePath),
      takeUntil(this.onDestroy$),
      shareReplay(1),
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private setUpObservable() {
    return this.transformValue.asObservable().pipe(
      filter((r) => Boolean(r)),
      distinctUntilChanged(),
      tap(() => (this.latestValue = undefined)),
      switchMap((imagePath: string) =>
        this.http
          .get(imagePath, { observe: 'response', responseType: 'blob' })
          .pipe(
            map((response: HttpResponse<Blob>) =>
              URL.createObjectURL(response.body as Blob),
            ),
            tap((blobUrl) => {
              this.revokeLatestBlob();
              this.latestBlobUrl = blobUrl;
            }),
            map((unsafeBlobUrl: string) =>
              this.sanitizer.bypassSecurityTrustUrl(unsafeBlobUrl),
            ),
            filter((blobUrl) => blobUrl !== this.latestValue),
            catchError(() => of(this.errorImagePath)),
          ),
      ),
      tap((imagePath: string | SafeUrl) => {
        this.latestValue = imagePath;
      }),
      finalize(() => {
        this.revokeLatestBlob();
      }),
    );
  }
  private setLoadingAndErrorImagePaths(
    loadingImagePath: string = this.defaultLoadingImagePath,
    errorImagePath: string = this.defaultErrorImagePath,
  ): void {
    if (this.loadingImagePath && this.errorImagePath) {
      return;
    }
    this.loadingImagePath = loadingImagePath;
    this.errorImagePath = errorImagePath;
  }
  private revokeLatestBlob() {
    if (this.latestBlobUrl) {
      URL.revokeObjectURL(this.latestBlobUrl);
      this.latestBlobUrl = undefined;
    }
  }
}
