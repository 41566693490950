import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getQueryString } from '../jsHelper';
import { Item } from './base-store.service';
import { IFilterDto } from '../FilterTypeDtos/i-filter-dto';
export abstract class BaseService<TGetDto extends Item, TPutDto, TPostDto> {
  constructor(
    protected apiBaseUrl: string,
    protected http: HttpClient,
    public path: string,
  ) {}
  getAll(
    filter?: IFilterDto,
    limit?: number,
    operator?: 'or' | 'and',
  ): Observable<TGetDto[]> {
    return this.http.get<TGetDto[]>(
      this.apiBaseUrl + this.path + getQueryString(filter, limit, operator),
    );
  }
  getById(id: number): Observable<TGetDto> {
    return this.http.get<TGetDto>(this.apiBaseUrl + this.path + '/' + id);
  }
  update(id: number, value: TPutDto) {
    return this.http.put(this.apiBaseUrl + this.path + '/' + id, value);
  }
  create(value: TPostDto): Observable<TGetDto> {
    return this.http.post<TGetDto>(this.apiBaseUrl + this.path, value);
  }
  delete(id: number) {
    return this.http.delete(this.apiBaseUrl + this.path + '/' + id);
  }
}
