import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(
    value: number | null,
    type: 'long' | 'short' | undefined = undefined,
  ): string {
    if (value == null) {
      return '';
    }
    return this.convertMinutesToTimeString(value, type == 'long');
  }

  convertMinutesToTimeString(valueToConvert: number, long: boolean) {
    let years = Math.floor(valueToConvert / (60 * 24 * 365));
    let rest = valueToConvert - years * (60 * 24 * 365);
    let weeks = Math.floor(rest / (60 * 24 * 7));
    rest = rest - weeks * (60 * 24 * 7);
    let days = Math.floor(rest / (60 * 24));
    rest = rest - days * (60 * 24);
    let hours = Math.floor(rest / 60);
    rest = rest - hours * 60;
    let minutes = Math.floor(rest);
    if (long) {
      return this.getLongString(years, weeks, days, hours, minutes);
    }
    return this.getShortString(years, weeks, days, hours, minutes);
  }
  getShortString(
    years: number,
    weeks: number,
    days: number,
    hours: number,
    minutes: number,
  ) {
    const result =
      (years ? years + 'y ' : '') +
      (weeks ? weeks + 'w ' : '') +
      (days ? days + 'd ' : '') +
      (hours ? hours + 'h ' : '') +
      (minutes ? minutes + 'm' : '');
    if (result) {
      return result;
    }
    return '< 1m';
  }
  getLongString(
    years: number,
    weeks: number,
    days: number,
    hours: number,
    minutes: number,
  ) {
    const result =
      (years ? years + (years > 1 ? ' Jahre ' : ' Jahr ') : '') +
      (weeks ? weeks + (weeks > 1 ? ' Wochen ' : ' Woche ') : '') +
      (days ? days + (days > 1 ? ' Tage ' : ' Tag ') : '') +
      (hours ? hours + (hours > 1 ? ' Stunden ' : ' Stunde ') : '') +
      (minutes ? minutes + (minutes > 1 ? ' Minuten ' : ' Minute ') : '');
    if (result) {
      return result;
    }
    return 'Unter einer Minute';
  }
}
