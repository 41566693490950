import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { UserPermissionsStoreService } from '../../services/user-permissions-store.service';
import { PermissionMissingComponent } from '../permission-missing/permission-missing.component';

@Directive({
  standalone: true,
  selector: '[ifPermission]',
})
export class IfPermissionDirective implements OnDestroy {
  private onDestroy$ = new Subject();
  @Input() set ifPermission(permission: number | number[]) {
    this.permission = permission;
    this.userPermissionsStore
      .checkOrPermissionsAsync(
        typeof permission == 'number' ? [permission] : permission,
      )
      .pipe(
        takeUntil(this.onDestroy$),
      )
      .subscribe((hasPermission) => {
        this.hasPermission = hasPermission;
        if (hasPermission) {
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
          const component = this.viewContainer.createComponent(
            PermissionMissingComponent,
          );
          component.setInput('permission', this.permission);
        }
      });
  }
  permission?: number | number[];
  hasPermission = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userPermissionsStore: UserPermissionsStoreService,
  ) {}
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
