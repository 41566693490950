import { Pipe, PipeTransform } from '@angular/core';
import { BaseStoreService, Item } from '../../services/base-store.service';
import { Observable } from 'rxjs/internal/Observable';

@Pipe({
  standalone: true,
  name: 'getItem',
})
export class GetItemPipe implements PipeTransform {
  transform<TGetDto extends Item, TPutDto, TPostDto>(
    value: number,
    ...args: BaseStoreService<TGetDto, TPutDto, TPostDto>[]
  ): Observable<TGetDto> | null {
    const store = args[0] as BaseStoreService<TGetDto, TPutDto, TPostDto>;
    if (store) {
      return store.getSingle(value);
    }
    return null;
  }
}
