import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileItem } from '../../services/file.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private _uploadProgress$ = new BehaviorSubject<number>(0);
  uploadProgress$: Observable<number> = this._uploadProgress$.asObservable();

  constructor() {}
  setUploadFileEvent(event: HttpEvent<FileItem>) {
    if (event.type == HttpEventType.Sent) {
      this._uploadProgress$.next(-1);
    } else if (event.type == HttpEventType.UploadProgress && event.total) {
      this._uploadProgress$.next(
        Math.round(100 * (event.loaded / event.total))
      );
    } else if (event.type == HttpEventType.Response && event.ok) {
      this._uploadProgress$.next(0);
    }
  }
}
