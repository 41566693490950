import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { map, Observable } from 'rxjs';
import { FileUploadService } from './file-upload.service';
import { Device, DeviceService } from '../../services/device.service';
import { FileItem } from '../../services/file.service';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  standalone: true,
  imports: [CommonModule, ButtonModule, SpinnerComponent],
  selector: 'sol-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  @Input() requiredFileType?: string;
  @Input() set uploadEventChanged(uploadEvent: HttpEvent<FileItem> | null) {
    if (uploadEvent) {
      this.fileUploadService.setUploadFileEvent(uploadEvent);
    }
  }
  @Output() fileUploading = new EventEmitter<File>();

  fileName = '';
  isNotWebView$: Observable<boolean>;
  uploadProgress$: Observable<number>;
  constructor(
    deviceService: DeviceService,
    private fileUploadService: FileUploadService,
  ) {
    this.isNotWebView$ = deviceService.device$.pipe(
      map((device: Device) => {
        if (device == Device.Mobile || device == Device.Tablet) {
          return true;
        } else {
          return false;
        }
      }),
    );
    this.uploadProgress$ = fileUploadService.uploadProgress$;
  }
  onFileDropped(event: any) {
    if (event) {
      this.fileUploadService.setUploadFileEvent({ type: HttpEventType.Sent });
      this.sendFile(event);
    }
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileUploadService.setUploadFileEvent({ type: HttpEventType.Sent });
      this.sendFile(file);
    }
  }
  sendFile(file: File) {
    this.fileName = file.name;
    this.fileUploading.emit(file);
  }
}
