<sol-loading-screen
  [isLoading]="loading$ | async"
  class="w-[315px] p-3"
  *ngIf="currentAuthUser$ | async as currentUser"
>
  <form
    [formGroup]="form"
    class="flex flex-wrap"
    autocomplete="off"
    *ngIf="
      currentUser?.hasUserWritePermission ||
      currentUser?.id == (user$ | async)?.id
    "
  >
    <fieldset class="flex flex-wrap gap-2 border-none">
      <header class="w-full text-center">
        <h1>Account-Einstellungen</h1>
      </header>
      <sol-textbox
        label="Benutzername"
        controlName="userName"
        [enabled]="!(user$ | async)"
        [required]="true"
      >
        <input
          [readonly]="user$ | async"
          required
          formFocus
          class="e-input"
          type="text"
          name="userName"
          formControlName="userName"
          autocomplete="off"
        />
      </sol-textbox>
      <sol-textbox label="E-Mail (falls vorhanden)" controlName="email">
        <input
          class="e-input"
          type="text"
          name="email"
          formControlName="email"
          autocomplete="off"
        />
      </sol-textbox>
      <sol-textbox [required]="true" label="Vorname" controlName="firstName">
        <input
          required
          class="e-input"
          type="text"
          name="firstName"
          formControlName="firstName"
          autocomplete="off"
        />
      </sol-textbox>
      <sol-textbox [required]="true" label="Nachname" controlName="lastName">
        <input
          required
          class="e-input"
          type="text"
          name="lastName"
          formControlName="lastName"
          autocomplete="off"
        />
      </sol-textbox>

      <ejs-dropdownlist
        [dataSource]="
          EnumUserStatuses
            | keyvalueEnum
            | enumTranslator: 'Account.Status'
            | async
        "
        [fields]="{ text: 'key', value: 'value' }"
        [value]="null"
        placeholder="Status"
        floatLabelType="Auto"
        formControlName="userStatusId"
      ></ejs-dropdownlist>
      <sol-textbox
        label="Organisation"
        controlName="organization"
        [enabled]="false"
        *ngIf="currentUser.isSystemUser"
      >
        <input
          readonly
          class="e-input"
          type="text"
          name="organization"
          formControlName="organization"
          autocomplete="off"
        />
      </sol-textbox>
      <div class="pl-3 pt-1" *ngIf="errors$ | async as errors">
        <i
          *ngFor="let error of errors"
          style="color: red"
          class="block text-sm"
          >{{ error }}</i
        >
      </div>
      <div>
        <ng-container *ngIf="user$ | async as user">
          <div>
            <label class="mr-3">Email bestätigt</label>
            <div class="mt-1 align-sub">
              <ejs-switch
                onLabel="JA"
                offLabel="NEIN"
                [disabled]="true"
                [checked]="user.emailConfirmed"
              ></ejs-switch>
            </div>
          </div>
          <div>
            <label class="mr-3">2-Faktor Authentifizierung aktiviert</label>
            <div class="mt-1 align-sub">
              <ejs-switch
                onLabel="JA"
                offLabel="NEIN"
                [disabled]="true"
                [checked]="user.twoFactorEnabled"
              ></ejs-switch>
            </div>
          </div>
        </ng-container>
        <div class="my-3 flex flex-col gap-2">
          <button
            [disabled]="loading$ | async"
            ejs-button
            type="button"
            name="button"
            class="icon-button w-fit"
            (click)="submit(initialPasswordDialog)"
          >
            <div>
              <i class="fa-regular fa-floppy-disk"></i>
              Speichern
            </div>
          </button>
          <button
            *ngIf="currentUser.id == (user$ | async)?.id"
            [disabled]="loading$ | async"
            ejs-button
            type="button"
            name="button"
            class="icon-button"
            routerLink="/authenticator"
          >
            2-Faktor Authentifizierung einrichten
          </button>
          <button
            (click)="apiKeyDetails.visible = true"
            [disabled]="loading$ | async"
            ejs-button
            type="button"
            name="button"
            class="icon-button"
          >
            API-Key verwalten
          </button>
          <button
            *ngIf="currentUser.isSystemUser"
            (click)="setOrganizationDialog.visible = true"
            [disabled]="loading$ | async"
            ejs-button
            type="button"
            name="button"
            class="icon-button"
          >
            Organisation setzen
          </button>
        </div>
        <auth-account-set-password
          *ngIf="currentUser.hasUserWritePermission && !(user$ | async)?.email"
          [user]="user$ | async"
          (passwordChanged)="onPasswordChanged($event)"
        ></auth-account-set-password>
      </div>
    </fieldset>
  </form>
</sol-loading-screen>
<ejs-dialog
  #initialPasswordDialog
  [showCloseIcon]="true"
  header="Initiales Passwort setzen"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="initialPasswordDialog.hide()"
>
  <ng-template #content>
    <ng-container *ngIf="initialPasswordDialog.visible && !(user$ | async)">
      <form
        [formGroup]="initialPasswordForm"
        *ngIf="currentAuthUser$ | async as currentAuthUser"
      >
        <fieldset class="flex flex-wrap gap-2 border-none">
          <sol-textbox
            *ngIf="
              currentAuthUser.isSystemUser ||
              currentAuthUser.hasUserWritePermission
            "
            [required]="true"
            label="Passwort"
            controlName="password"
            [validationMessages]="{
              pattern:
                'Zwischen 10 und 64 Zeichen, mindestens 1 Zahl, 1 Sonderzeichen, 1 Kleinbuchstaben und 1 Großbuchstaben',
            }"
          >
            <input
              required
              class="e-input"
              type="password"
              name="password"
              formControlName="password"
              autocomplete="new-password"
            />
          </sol-textbox>
          <div class="my-3 flex gap-2">
            <button
              [disabled]="loading$ | async"
              ejs-button
              type="button"
              name="button"
              class="icon-button"
              (click)="submitInitialPassword(initialPasswordDialog)"
            >
              <div>
                <i class="fa-regular fa-floppy-disk"></i>
                Speichern
              </div>
            </button>
            <button
              [disabled]="loading$ | async"
              ejs-button
              type="button"
              name="button"
              class="icon-button"
              (click)="submitWithoutPassword(initialPasswordDialog)"
            >
              <div>
                <i class="fa-regular fa-floppy-disk"></i>
                Ohne Passwort anlegen (API-Key User)
              </div>
            </button>
            <button
              [disabled]="loading$ | async"
              ejs-button
              type="button"
              name="button"
              class="icon-button"
              (click)="initialPasswordDialog.hide()"
            >
              Abbrechen
            </button>
          </div>
        </fieldset>
      </form>
    </ng-container>
  </ng-template>
</ejs-dialog>
<ejs-dialog
  #setOrganizationDialog
  [showCloseIcon]="true"
  header="Organisation setzen"
  width="600"
  [visible]="false"
  isModal="true"
  (overlayClick)="setOrganizationDialog.hide()"
>
  <ng-template #content>
    <ng-container *ngIf="setOrganizationDialog.visible && (user$ | async)">
      <form
        [formGroup]="setOrganizationForm"
        *ngIf="currentAuthUser$ | async as currentUser"
      >
        <fieldset class="flex flex-wrap gap-2 border-none">
          <sol-textbox
            label="Organisation"
            controlName="organization"
            *ngIf="currentUser.isSystemUser"
          >
            <input
              class="e-input"
              type="text"
              name="organization"
              formControlName="organization"
              autocomplete="off"
            />
          </sol-textbox>
          <div class="my-3 flex gap-2">
            <button
              [disabled]="loading$ | async"
              ejs-button
              type="button"
              name="button"
              class="icon-button"
              (click)="submitOrganization(setOrganizationDialog)"
            >
              <div>
                <i class="fa-regular fa-floppy-disk"></i>
                Speichern
              </div>
            </button>
            <button
              [disabled]="loading$ | async"
              ejs-button
              type="button"
              name="button"
              class="icon-button"
              (click)="setOrganizationDialog.hide()"
            >
              Abbrechen
            </button>
          </div>
        </fieldset>
      </form>
    </ng-container>
  </ng-template>
</ejs-dialog>
<ejs-dialog
  #apiKeyDetails
  [showCloseIcon]="true"
  header="API-Key Details"
  width="600"
  [visible]="false"
  (close)="apiKeyDetails.refresh()"
  isModal="true"
  (overlayClick)="apiKeyDetails.hide()"
>
  <ng-template #content>
    <auth-api-key-details
      [user]="user$ | async"
      (apiKeyDeleted)="onApiKeyDeleted($event)"
      (apiKeyGenerated)="onApiKeyGenerated($event)"
    ></auth-api-key-details>
  </ng-template>
</ejs-dialog>
