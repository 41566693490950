import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { UserPermissionsStoreService } from '../../services/user-permissions-store.service';
import { TranslocoService } from '@ngneat/transloco';

@Directive({
  standalone: true,
  selector: '[ifButtonPermission]',
})
export class IfButtonPermissionDirective implements OnDestroy {
  private onDestroy$ = new Subject();
  isDisabled?: boolean;
  @Input('condition') set condition(condition: boolean | undefined) {
    this._condition$.next(condition);
  }
  @Input('conditionText') conditionText?: string;
  @Input() set ifButtonPermission(permission: number | number[]) {
    this._permission$.next(permission);
  }
  private _permission$ = new ReplaySubject<number | number[]>();
  private _condition$ = new BehaviorSubject<boolean | undefined>(undefined);
  constructor(
    private userPermissionsStore: UserPermissionsStoreService,
    private el: ElementRef,
    private transloco: TranslocoService,
  ) {
    this.setTooltip();
  }
  setTooltip() {
    combineLatest([this._condition$, this._permission$])
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(([condition, permission]) => {
          return this.transloco
            .selectTranslate(
              typeof permission == 'number'
                ? [
                    'Permission.' +
                      this.userPermissionsStore.Permissions[permission],
                  ]
                : permission.map(
                    (p) =>
                      'Permission.' + this.userPermissionsStore.Permissions[p],
                  ),
              {},
              this.transloco.getActiveLang(),
            )
            .pipe(
              switchMap((permissionName: string[]) =>
                this.userPermissionsStore
                  .checkOrPermissionsAsync(
                    typeof permission == 'number' ? [permission] : permission,
                  )
                  .pipe(
                    takeUntil(this.onDestroy$),
                    tap((hasPermission) => {
                      const button = this.el.nativeElement as HTMLButtonElement;
                      if (this.isDisabled == undefined) {
                        this.isDisabled = button.disabled;
                      }
                      if (hasPermission) {
                        if (button.hasAttribute('data-tooltip')) {
                          button.disabled = this.isDisabled;
                          button.removeAttribute('data-tooltip');
                        }
                      } else {
                        button.disabled = true;
                        const tooltip =
                          permissionName.length == 1
                            ? 'Ihnen fehlt das Recht:' +
                              '\n' +
                              permissionName[0]
                            : 'Ihnen fehlt eines dieser Rechte: ' +
                              permissionName.join('\r');
                        button.setAttribute('data-tooltip', tooltip);
                      }
                      if (
                        hasPermission &&
                        condition == false &&
                        this.conditionText
                      ) {
                        button.disabled = true;
                        const tooltip = this.conditionText;
                        button.setAttribute('data-tooltip', tooltip);
                      }
                    }),
                  ),
              ),
            );
        }),
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
