import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  FieldSettingsModel,
  NodeSelectEventArgs,
  Sidebar,
  SidebarModule,
  TreeViewComponent,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import { Observable } from 'rxjs/internal/Observable';
import { combineLatest, map } from 'rxjs';
import { MenuLink, MenuStoreService } from '../../services/menu-store.service';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserPermissionsStoreService } from '../../services/user-permissions-store.service';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SidebarModule, TreeViewModule],
  selector: 'sol-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent {
  @ViewChild('sidebar') sidebar?: Sidebar;
  fields$: Observable<FieldSettingsModel>;
  private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this._isAuthenticated$.asObservable();
  @Input() set isAuthenticated(isAuthenticated: boolean) {
    this._isAuthenticated$.next(isAuthenticated);
  }
  @Input() customClass?: string;
  constructor(
    menuStore: MenuStoreService,
    private router: Router,
    userPermissionsStore: UserPermissionsStoreService,
  ) {
    this.fields$ = combineLatest([
      userPermissionsStore.userPermissions$,
      menuStore.menuItems$
        .pipe
        // mergeAll(),
        // mergeMap((cat) => {
        //   const children =
        //     cat.children?.map((item) =>
        //       item?.permissions
        //         ? userPermissionsStore
        //             .checkOrPermissionsAsync(item.permissions)
        //             .pipe(
        //               map((hasPermission) =>
        //                 hasPermission ? item : undefined,
        //               ),
        //             )
        //         : of(item),
        //     ) ?? [];
        //     children
        //   return { ...cat, children: children };
        // }),
        (),
      this.isAuthenticated$,
    ]).pipe(
      map(([userPermissions, items, isAuthenticated]) => {
        const filteredItems = items.map((cat) => {
          const children =
            cat.children?.filter((item) =>
              item.permissions
                ? userPermissionsStore.checkOrPermissions(
                    item.permissions,
                    userPermissions,
                  )
                  ? item
                  : undefined
                : true,
            ) ?? [];
          return { ...cat, children: children };
        });
        return {
          dataSource: filteredItems
            .filter((item) => item.anonymous || isAuthenticated)
            .map((item) => {
              if (isAuthenticated) {
                return item;
              } else {
                const newItem = {
                  ...item,
                  children: item.children?.filter(
                    (child) =>
                      child && (child.anonymous || child.anonymousOnly),
                  ),
                  iconText: item.iconText,
                };
                return newItem;
              }
            }),
          id: 'text',
          text: 'text',
          child: 'children',
          // iconCss: 'iconText',
          // navigateUrl: 'link',
        };
      }),
    );
  }
  public toggle() {
    this.sidebar?.toggle();
  }
  onItemClicked(treeview: TreeViewComponent, event: NodeSelectEventArgs) {
    var data = treeview.getTreeData(event.node);
    const menuItem: MenuLink = data[0] as any;
    if (menuItem.link) this.router.navigate([menuItem.link]);
  }
}
