import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs';
import { ComponentStateService } from '../../services/component-state.service';
import { SelectorConfig } from '../../selector-config';
import { SearchStoreService } from '../../services/search-store.service';
import { Item } from '../../services/base-store.service';
import {
  SortService,
  ResizeService,
  RowSelectEventArgs,
  GridModule,
} from '@syncfusion/ej2-angular-grids';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { CommonModule } from '@angular/common';
import { IfPermissionDirective } from '../directives/if-permission.directive';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoadingScreenComponent,
    GridModule,
    ReactiveFormsModule,
    IfPermissionDirective,
  ],
  selector: 'sol-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ComponentStateService,
    SearchStoreService,
    SortService,
    ResizeService,
  ],
})
export class SelectorComponent<TGetDto extends Item, TPutDto, TPostDto>
  implements OnInit, OnDestroy
{
  private onDestroy$ = new Subject();
  @Output() itemSelect = new EventEmitter<TGetDto>();
  @Input() public selectorConfig!: SelectorConfig<TGetDto, TPutDto, TPostDto>;
  @Input() public withSearch = true;
  private _exceptedItems$ = new BehaviorSubject<number[]>([]);
  private exceptedItems$ = this._exceptedItems$.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );
  @Input() set searchValue(value: string | null) {
    this.setSearchValue(value);
  }
  @Input() set exceptedItems(ids: number[] | undefined | null) {
    this.setExpectedItems(ids ?? []);
  }
  loading$: Observable<boolean>;
  searchControl = new FormControl<string>('');
  filteredItems$?: Observable<TGetDto[]>;
  constructor(
    private componentStateService: ComponentStateService,
    private searchStoreService: SearchStoreService<TGetDto, TPutDto, TPostDto>,
  ) {
    this.loading$ = componentStateService.loading$;
    const input$: Observable<string | null> = this.searchControl.valueChanges;
    input$
      .pipe(takeUntil(this.onDestroy$), startWith(''), debounceTime(500))
      .subscribe((value) => searchStoreService.setSearchValue(value));

    this.filteredItems$ = combineLatest([
      this.searchStoreService.filteredItems$,
      this.exceptedItems$,
    ]).pipe(
      map(([items, exceptedItems]) =>
        items.filter((item) => !exceptedItems.includes(item.id)).slice(0, 500),
      ),
    );
  }
  ngOnInit(): void {
    this.searchStoreService.setSelectorConfig(this.selectorConfig);
  }
  setSearchValue(value: string | null) {
    this.searchControl.setValue(value);
  }
  setExpectedItems(expectedItems: number[]) {
    this._exceptedItems$.next(expectedItems);
  }
  onItemSelect(e: RowSelectEventArgs) {
    this.itemSelect.emit(e.data as TGetDto);
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
