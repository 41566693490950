import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { SetupCodeDto } from '../../dto/setup-code-dto';
import { LoginResultDto } from '../../dto/login-result-dto';
import { AUTHENTICATION_DEFAULT_OPTIONS } from '../Tokens/tokens';
import { AuthenticationConfig } from '../authentication-config';
import { RefreshTokenRequestDto } from '../../dto/refresh-token-request-dto';
import { ForgotPasswordDto } from '../../dto/forgot-password-dto';
import { ApiResponseDto } from '../../dto/api-response-dto';
import { ResetPasswordDto } from '../../dto/reset-password-dto';
import { ConfirmEmailDto } from '../../dto/confirm-email-dto';
import { ResetAuthenticatorDto } from '../../dto/reset-authenticator-dto';
import { ConfirmAuthenticatorSetupDto } from '../../dto/confirm-authenticator-setup-dto';
import { LoginRequestDto } from '../../dto/login-request-dto';
import { RegisterUserDto } from '../../dto/register-user-dto';
import { OAuthClientDto } from '../../dto/oauth-client-dto';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private serviceUrl: string;
  constructor(
    @Inject(AUTHENTICATION_DEFAULT_OPTIONS)
    authConfig: AuthenticationConfig,
    private http: HttpClient,
  ) {
    this.serviceUrl = authConfig.apiBaseUrl + 'authentication';
  }

  login(loginRequest: LoginRequestDto) {
    return this.http.post<LoginResultDto>(
      this.serviceUrl + '/login',
      loginRequest,
      {
        withCredentials: true,
      },
    );
  }

  refreshToken(refreshTokenRequest: RefreshTokenRequestDto) {
    return this.http.post<LoginResultDto>(
      this.serviceUrl + '/refresh',
      refreshTokenRequest,
      {
        withCredentials: true,
      },
    );
  }

  register(registerUserDto: RegisterUserDto): Observable<ApiResponseDto> {
    return this.http.post<ApiResponseDto>(
      this.serviceUrl + '/register',
      registerUserDto,
    );
  }
  confirmEmail(confirmEmail: ConfirmEmailDto): Observable<ApiResponseDto> {
    return this.http.put<ApiResponseDto>(
      this.serviceUrl + '/register',
      confirmEmail,
    );
  }
  resendConfirmationEmail(loginRequest: LoginRequestDto) {
    return this.http.post<ApiResponseDto>(
      this.serviceUrl + '/send-confirmation-email',
      loginRequest,
      {
        withCredentials: true,
      },
    );
  }
  sendForgotPasswordEmail(
    forgotPassword: ForgotPasswordDto,
  ): Observable<ApiResponseDto> {
    return this.http.post<ApiResponseDto>(
      this.serviceUrl + '/forgot-password',
      forgotPassword,
    );
  }

  resetPassword(resetPassword: ResetPasswordDto) {
    return this.http.post<ApiResponseDto>(
      this.serviceUrl + '/reset-password',
      resetPassword,
    );
  }

  getAuthenticatorSetupCode() {
    return this.http.get<SetupCodeDto>(this.serviceUrl + '/authenticator');
  }
  confirmAuthenticatorSetup(
    confirmAuthenticatorSetupDto: ConfirmAuthenticatorSetupDto,
  ) {
    return this.http.post<ApiResponseDto>(
      this.serviceUrl + '/authenticator',
      confirmAuthenticatorSetupDto,
    );
  }
  sendResetAuthenticatorSetupCode(cred: LoginRequestDto) {
    return this.http.post<ApiResponseDto>(
      this.serviceUrl + '/authenticator/send-reset',
      cred,
    );
  }
  resetAuthenticatorSetupCode(resetAuthenticatorDto: ResetAuthenticatorDto) {
    return this.http.post<SetupCodeDto>(
      this.serviceUrl + '/authenticator/reset',
      resetAuthenticatorDto,
    );
  }

  getOAuthClients() {
    return this.http.get<OAuthClientDto[]>(this.serviceUrl + '/oauthclient');
  }
}
