import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, tap } from 'rxjs';
import { UserPermissionsStoreService } from './services/user-permissions-store.service';
import { SitePermissionAlertService } from './site-permission-alert.service';

export const canAccess: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | Observable<boolean> => {
  const userPermissionsStore = inject(UserPermissionsStoreService);
  const alertService = inject(SitePermissionAlertService);
  const router = inject(Router);
  const permissions = route.data['permissions'] ?? [route.data['permission']];
  if (permissions) {
    return userPermissionsStore.checkOrPermissionsAsync(permissions).pipe(
      tap((canAccess) => {
        if (!canAccess) {
          router.navigate(['error'], {
            queryParams: { redirectURL: encodeURI(state.url) },
            // queryParamsHandling: 'preserve',
          });
          alertService.sendAlert({
            permissions: permissions,
            url: state.url,
          });
        }
      }),
    );
  }
  return true;
};

export const canAccessChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => canAccess(route, state);
