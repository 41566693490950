import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable, map, share, timer } from 'rxjs';
import { Device, DeviceService } from '../../services/device.service';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ImageComponent } from '../image/image.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppBarModule } from '@syncfusion/ej2-angular-navigations';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AppBarModule,
    ButtonModule,
    ImageComponent,
  ],
  selector: 'sol-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuComponent {
  @Input() barTitle: string = '';
  @Input() displayCurrentTime: boolean = false;
  @Output() menuClick = new EventEmitter<boolean>();

  private _showLogout$ = new BehaviorSubject<boolean>(false);
  showLogout$ = this._showLogout$.asObservable();
  isMobile$: Observable<boolean>;
  @Input() set showLogout(showLogout: boolean) {
    this._showLogout$.next(showLogout);
  }
  currentDatetime$: Observable<Date> = timer(0, 1000).pipe(
    map(() => new Date()),
    share(),
  );

  onMenuClick() {
    this.menuClick.emit(true);
  }
  constructor(deviceService: DeviceService) {
    this.isMobile$ = deviceService.device$.pipe(
      map((device) => device == Device.Mobile),
    );
  }
}
