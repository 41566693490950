import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../../Tokens/tokens';
import { CommonSolutionConfig } from '../../common-solution-config';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  selector: 'sol-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() icon: string = '';
  path?: string;
  constructor(
    @Inject(COMMON_SOLUTION_DEFAULT_OPTIONS) config: CommonSolutionConfig,
  ) {
    this.path = config.iconPath;
  }
}
