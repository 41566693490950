import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ComponentStateService,
  FormFocusDirective,
  TextboxComponent,
} from 'ngx-common-solution';
import { PasswordPattern } from '../../password-pattern';
import { AdminService } from '../services/admin.service';
import { EMPTY, Observable, catchError, finalize, of } from 'rxjs';
import { AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { CommonModule } from '@angular/common';
import { AuthenticationStoreService } from '../stores/authentication-store.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    ButtonModule,
    FormFocusDirective,
    TextboxComponent,
    SwitchModule,
  ],
  selector: 'auth-account-set-password',
  templateUrl: './account-set-password.component.html',
  styleUrls: ['./account-set-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSetPasswordComponent {
  @Input() user?: AuthUserGetDto | null;
  @Output() passwordChanged = new EventEmitter();
  newPasswordForm = this.fb.group({
    password: ['', [Validators.required, Validators.pattern(PasswordPattern)]],
  });
  loading$: Observable<boolean>;
  currentUser$: Observable<AuthUserGetDto | null>;
  constructor(
    private fb: FormBuilder,
    private componentState: ComponentStateService,
    private adminService: AdminService,
    authStore: AuthenticationStoreService,
  ) {
    this.loading$ = componentState.loading$;
    this.currentUser$ = authStore.authUser$;
  }
  onNewPasswordSubmit(userId: number, newPasswordDialog: any) {
    if (this.newPasswordForm.invalid) {
      this.newPasswordForm.markAllAsTouched();
      return;
    }
    if (this.newPasswordForm.value.password) {
      this.componentState.setLoading(true);
      this.adminService
        .setNewPassword(userId, {
          newPassword: this.newPasswordForm.value.password,
        })
        .pipe(
          finalize(() => this.componentState.setLoading(false)),
          catchError((err) => {
            if (err.status == 400) {
              const res = err.error as {
                code: string;
                description: string;
              }[];
              this.componentState.setErrors(res.map((r) => r.description));
              return EMPTY;
            }
            return of(err);
          }),
        )
        .subscribe((r) => this.passwordChanged.emit());
      newPasswordDialog.visible = false;
    }
  }
}
