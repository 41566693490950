<div
  class="e-float-input e-input-group {{ inputClasses }}"
  [ngClass]="{
    'e-error': (errorExists$ | async),
    'e-disabled': !enabled || (disabled$ | async)
  }"
  [formGroup]="form"
>
  <ng-content *ngIf="!link"></ng-content>
  <ng-container *ngIf="linkFormat">
    <input
      #hiddenInput
      class="e-input hidden"
      formControlName="{{ controlName }}"
      [disabled]="!enabled"
    />
    <div class="flex h-7 w-full pl-3">
      <a class="link-input my-auto" [routerLink]="link">
        {{ hiddenInput.value }}
      </a>
    </div>
  </ng-container>
  <span class="e-float-line"></span>
  <label
    class="e-float-text top-[13px]"
    [ngClass]="{
      'required-field': required,
      'e-label-top': labelTop || (labelTop$ | async)
    }"
    >{{ label }}</label
  >
  <span *ngIf="icon" class="e-input-group-icon e-input-popup-date p-0"
    ><button
      ejs-button
      type="button"
      name="button"
      class="textbox-btn mx-1 my-2 contents w-9 border-none py-0 pr-2"
      (click)="onButtonClick($event)"
      [disabled]="!enabled || (disabled$ | async)"
    >
      <sol-image
        [icon]="icon"
        class="mx-2 my-[2px] h-6 w-8 p-0"
      ></sol-image></button
  ></span>
  <span *ngIf="fontIcon" class="e-input-group-icon e-input-popup-date p-0"
    ><button
      ejs-button
      type="button"
      name="button"
      class="textbox-btn icon-button mx-1 w-9 border-none py-0 pr-2"
      (click)="onButtonClick($event)"
      [disabled]="!enabled || (disabled$ | async)"
    >
      <i class="fa-solid fa-{{ fontIcon }} m-2px"></i></button
  ></span>
</div>
<ng-container *ngFor="let message of currentErrorMessages$ | async">
  <p class="error-text" role="alert" data-testid="control-error">
    <span class="control-error-mark" aria-hidden="true">{{ message }}</span>
  </p>
</ng-container>
