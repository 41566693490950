import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivate } from './auth.guard';
import { canBlockNavigationGuard } from './can-block-navigation.guard';

const routes: Routes = [
  {
    path: 'code',
    loadComponent: () =>
      import('./code/code.component').then((mod) => mod.CodeComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then((mod) => mod.LoginComponent),
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./logout/logout.component').then((mod) => mod.LogoutComponent),
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./register/register.component').then(
        (mod) => mod.RegisterComponent,
      ),
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        (mod) => mod.ResetPasswordComponent,
      ),
  },
  {
    path: 'send-reset',
    loadComponent: () =>
      import('./send-reset-password/send-reset-password.component').then(
        (mod) => mod.SendResetPasswordComponent,
      ),
  },
  {
    path: 'authenticator',
    loadComponent: () =>
      import('./setup-authenticator/setup-authenticator.component').then(
        (mod) => mod.SetupAuthenticatorComponent,
      ),
    canActivate: [canActivate],
  },
  {
    path: 'authenticator/reset',
    loadComponent: () =>
      import('./reset-authenticator/reset-authenticator.component').then(
        (mod) => mod.ResetAuthenticatorComponent,
      ),
  },
  {
    path: 'account/list',
    loadComponent: () =>
      import('./account-grid/account-grid.component').then(
        (mod) => mod.AccountGridComponent,
      ),
    canActivate: [canActivate],
  },
  {
    path: 'account',
    loadComponent: () =>
      import('./account-details/account-details.component').then(
        (mod) => mod.AccountDetailsComponent,
      ),
    data: { showCurrentAccount: false },
    canActivate: [canActivate],
  },
  {
    path: 'account/myaccount',
    loadComponent: () =>
      import('./account-details/account-details.component').then(
        (mod) => mod.AccountDetailsComponent,
      ),
    data: { showCurrentAccount: true },
    canActivate: [canActivate],
  },
  {
    path: 'account/change-password',
    loadComponent: () =>
      import('./change-password/change-password.component').then(
        (mod) => mod.ChangePasswordComponent,
      ),
    canActivate: [canActivate],
    canDeactivate: [canBlockNavigationGuard],
  },
  {
    path: 'account/fill-credentials',
    loadComponent: () =>
      import('./user-credentials/user-credentials.component').then(
        (mod) => mod.UserCredentialsComponent,
      ),
    canActivate: [canActivate],
    canDeactivate: [canBlockNavigationGuard],
  },
  {
    path: 'account/:id',
    loadComponent: () =>
      import('./account-details/account-details.component').then(
        (mod) => mod.AccountDetailsComponent,
      ),
    data: { showCurrentAccount: false },
    canActivate: [canActivate],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthenticationRoutingModule {}
