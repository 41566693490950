import { Inject, Injectable } from '@angular/core';
import { AUTHENTICATION_DEFAULT_OPTIONS } from '../Tokens/tokens';
import { HttpClient } from '@angular/common/http';
import { AuthUserGetDto as AuthUserGetDto } from '../../dto/auth-user-get-dto';
import { AuthenticationConfig } from '../authentication-config';
import { AuthUserPutDto } from '../../dto/auth-user-put-dto';
import { AuthUserPostDto } from '../../dto/auth-user-post-dto';
import { NewPasswordDto } from '../../dto/new-password-dto';
import { Observable } from 'rxjs';
import { ApiKeyPutDto } from '../../dto/api-key-put-dto';
import { OrganizationPutDto } from '../../dto/organization-put-dto';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private serviceUrl: string;
  constructor(
    @Inject(AUTHENTICATION_DEFAULT_OPTIONS)
    authConfig: AuthenticationConfig,
    private http: HttpClient,
  ) {
    this.serviceUrl = authConfig.apiBaseUrl + 'admin';
  }

  getAllUsers() {
    return this.http.get<AuthUserGetDto[]>(this.serviceUrl);
  }
  getSingle(userId: number) {
    return this.http.get<AuthUserGetDto>(`${this.serviceUrl}/${userId}`);
  }
  createUser(user: AuthUserPostDto): Observable<AuthUserGetDto> {
    return this.http.post<AuthUserGetDto>(`${this.serviceUrl}`, user);
  }
  update(userId: number, userPostPutDto: AuthUserPutDto) {
    return this.http.put(`${this.serviceUrl}/${userId}`, userPostPutDto);
  }
  setNewPassword(userId: number, newPasswordDto: NewPasswordDto) {
    return this.http.post(
      `${this.serviceUrl}/${userId}/password`,
      newPasswordDto,
    );
  }
  logoutAllDevices(userId: number) {
    return this.http.post(`${this.serviceUrl}/${userId}/logout-all`, {});
  }

  addApiKey(userId: number, apiKey: ApiKeyPutDto) {
    return this.http.put(`${this.serviceUrl}/${userId}/apikey`, apiKey);
  }
  deleteApiKey(userId: number) {
    return this.http.delete(`${this.serviceUrl}/${userId}/apikey`);
  }
  setOrganization(userId: number, dto: OrganizationPutDto) {
    return this.http.put(`${this.serviceUrl}/${userId}/organization`, dto);
  }
}
