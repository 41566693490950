import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable, shareReplay } from 'rxjs';

export enum Device {
  Mobile,
  Tablet,
  Web,
  WebLarge,
}
@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  device$: Observable<Device>;
  constructor(breakpointObserver: BreakpointObserver) {
    this.device$ = breakpointObserver
      .observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.XLarge,
      ])
      .pipe(
        map((state) => {
          if (
            state.breakpoints[Breakpoints.HandsetLandscape] ||
            state.breakpoints[Breakpoints.HandsetPortrait]
          ) {
            return Device.Mobile;
          } else if (
            state.breakpoints[Breakpoints.TabletLandscape] ||
            state.breakpoints[Breakpoints.TabletPortrait]
          ) {
            return Device.Tablet;
          } else if (state.breakpoints[Breakpoints.XLarge]) {
            return Device.WebLarge;
          } else {
            return Device.Web;
          }
        }),
        distinctUntilChanged(),
        shareReplay(1),
      );
  }
}
