import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ComponentStateService,
  FormFocusDirective,
  LoadingScreenComponent,
  MatchValidator,
  TextboxComponent,
} from 'ngx-common-solution';
import {
  finalize,
  catchError,
  EMPTY,
  of,
  Observable,
  shareReplay,
  map,
  tap,
  switchMap,
  take,
} from 'rxjs';
import { AuthUserService } from '../services/auth-user.service';
import { AuthenticationStoreService } from '../stores/authentication-store.service';
import { PasswordPattern } from '../../password-pattern';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { Router } from '@angular/router';
import { ICanBlockNavigation } from '../can-block-navigation.guard';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    FormFocusDirective,
    TextboxComponent,
    LoadingScreenComponent,
  ],
  selector: 'auth-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStateService],
})
export class ChangePasswordComponent implements ICanBlockNavigation {
  form = this.fb.group({
    oldPassword: ['', [Validators.required]],
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(PasswordPattern),
        MatchValidator('passwordRepeat', true),
      ],
    ],
    passwordRepeat: ['', [Validators.required, MatchValidator('password')]],
  });
  loading$: Observable<boolean>;
  canDeactivateBlock$: Observable<boolean>;
  constructor(
    private fb: FormBuilder,
    private componentState: ComponentStateService,
    private userService: AuthUserService,
    private authStore: AuthenticationStoreService,
    private router: Router,
  ) {
    this.loading$ = componentState.loading$;
    this.canDeactivateBlock$ = authStore.authFlowInProgress$.pipe(
      map((r) => !r),
      shareReplay(1),
    );
  }
  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.form.value.oldPassword && this.form.value.password) {
      this.componentState.setLoading(true);
      this.userService
        .changePassword({
          oldPassword: this.form.value.oldPassword,
          newPassword: this.form.value.password,
        })
        .pipe(
          finalize(() => this.componentState.setLoading(false)),
          catchError((err) => this.checkResponseForError(err)),
          switchMap(() => this.authStore.authUser$),
          take(1),
          switchMap((authUser) =>
            this.authStore.loginUser({
              email: authUser?.userName!,
              password: this.form.value.password!,
            }),
          ),
          switchMap(() => this.userService.getCurrentAuthUser()),
          take(1),
        )
        .subscribe((user) => {
          this.authStore.setCurrentAuthUser(user);
          this.router.navigate(['']);
        });
    }
  }
  checkResponseForError(err: any) {
    if (err.status == 400) {
      const res = err.error as {
        code: string;
        description: string;
      }[];
      this.componentState.setErrors(res.map((r) => r.description));
      return EMPTY;
    }
    return of(err);
  }
}
