export function getScrollParent(element: HTMLElement, includeHidden: boolean) {
  var style = getComputedStyle(element);
  var excludeStaticParent = style.position === 'absolute';
  var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

  if (style.position === 'fixed') return document.body;
  for (
    var parent: HTMLElement | null = element;
    (parent = parent.parentElement);

  ) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
      return parent;
  }

  return document.body;
}
export function getQueryString(filter: any, limit?: number, operator?: string) {
  if (!filter) return '';
  var qs = [];
  const q =
    Object.keys(filter).length === 0
      ? ''
      : 's=' + encodeURIComponent(JSON.stringify(filter));
  if (q) qs.push(q);
  if (limit != undefined) qs.push('limit=' + limit);
  if (operator != undefined) qs.push('op=' + operator);
  return qs.length == 0 ? '' : '?' + qs.join('&');
}
export function formatString(str: string, val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}
export function isNullUndefinedOrWhitespace(
  value: string | undefined | null,
): boolean {
  return !value || value.length == 0 || value.match(/^\s*$/) !== null;
}
export function getQueryMap(query: string) {
  if (typeof query !== 'string') {
    return null;
  }

  var toType = (a: string) => {
      const match = {}.toString.call(a).match(/\s([a-zA-Z]+)/);
      return match ? match[1].toLowerCase() : '';
    },
    map: { [key: string]: any } = {};

  // map the hit query into a proper object
  query.replace(
    /([^&|\?=]+)=?([^&]*)(?:&+|$)/g,
    (match: string, key: string, value: string) => {
      if (key in map) {
        // the key already exists, so we need to check if it is an array, if not, make it an array and add the new value
        if (toType(map[key]) !== 'array') {
          // it's not an array - make it an array
          map[key] = [map[key]];
        }
        // push the new value into the array
        map[key].push(value);
      } else {
        // put the value into the map
        map[key] = value;
      }
      return ''; // Return an empty string to satisfy the replacer function's return type
    },
  );
  return map;
}
