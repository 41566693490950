import { Injectable, OnDestroy } from '@angular/core';
import { extractTouchedChanges, findFormControl } from '../helper-functions';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {
  AbstractControl,
  ControlContainer,
  ValidationErrors,
} from '@angular/forms';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  merge,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';

export interface ValidationMessages {
  [key: string]: string;
}
@Injectable({
  providedIn: 'root',
})
export class ControlErrorsService implements OnDestroy {
  private onDestroy$ = new Subject();
  private validationMessages: ValidationMessages = {};
  private internalControl?: AbstractControl;
  private _disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  disabled$ = this._disabled$.asObservable();
  valueChanged$ = new ReplaySubject<any>(1);
  labelTop$ = combineLatest([this.valueChanged$, this._disabled$]).pipe(
    map(
      (r, disabled) =>
        this.internalControl?.value != undefined &&
        this.internalControl.value != null &&
        this.internalControl.value?.toString(),
    ),
  );
  private _errors$: BehaviorSubject<ValidationErrors> =
    new BehaviorSubject<ValidationErrors>({});
  errors$ = this._errors$.asObservable();
  currentErrorMessages$ = this._errors$.pipe(
    map((errors) => {
      var currentMessages: string[] = [];
      if (this.validationMessages) {
        Object.keys(errors).forEach((key) => {
          const value = this.validationMessages[key];
          if (value) {
            currentMessages.push(value);
          }
        });
      } else {
        return Object.keys(errors);
      }
      return currentMessages;
    }),
  );
  private _changed$ = new Subject<boolean>();
  changed$ = this._changed$.asObservable();

  constructor() {}
  setControl(formControlName: string, controlContainer: ControlContainer) {
    this.internalControl = findFormControl(
      undefined,
      formControlName,
      controlContainer,
    );
    this.internalControl?.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        startWith(this.internalControl.value),
        distinctUntilChanged(),
      )
      .subscribe((v) => {
        this.valueChanged$.next(v);
      });
    const touched$ = extractTouchedChanges(this.internalControl);
    const statusChanges$ = this.internalControl.statusChanges.pipe(
      startWith('PENDING'),
    );
    merge(touched$, statusChanges$)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((r) => {
        this._changed$.next(true);
        if (this.internalControl?.disabled) {
          this._disabled$.next(true);
        }
        if (this.internalControl && this.internalControl.touched) {
          this.setErrors(this.internalControl.errors ?? {});
        }
      });
  }
  private setErrors(errors: ValidationErrors): void {
    this._errors$.next(errors);
  }
  setValidationMessages(messages: ValidationMessages): void {
    this.validationMessages = messages;
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
