import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  standalone: true,
  imports: [IconButtonComponent],
  selector: 'sol-icon-button-big',
  templateUrl: './icon-button-big.component.html',
  styleUrls: ['./icon-button-big.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonBigComponent {
  @Input() text: string = '';
  @Input() icon: string = "";
}
