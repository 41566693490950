import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMMON_SOLUTION_DEFAULT_OPTIONS } from '../Tokens/tokens';
import { CommonSolutionConfig } from '../common-solution-config';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import {
  AppBarModule,
  SidebarModule,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { GridModule } from '@syncfusion/ej2-angular-grids';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonModule,
    ClipboardModule,
    DialogModule,
    ReactiveFormsModule,
    SidebarModule,
    AppBarModule,
    TreeViewModule,
    RouterModule,
    ComboBoxModule,
    ListViewModule,
    GridModule,
  ],
  exports: [],
})
export class NgxCommonSolutionModule {
  static forRoot(
    config: CommonSolutionConfig,
  ): ModuleWithProviders<NgxCommonSolutionModule> {
    return {
      ngModule: NgxCommonSolutionModule,
      providers: [
        {
          provide: COMMON_SOLUTION_DEFAULT_OPTIONS,
          useValue: config,
        },
      ],
    };
  }
}
