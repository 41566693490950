import { Injectable } from '@angular/core';
import { DateFilterTypeDto } from '../FilterTypeDtos/date-filter-type-dto';
import { ConditionType } from '../Enums/condition-type';
import { NumberFilterTypeDto } from '../FilterTypeDtos/number-filter-type-dto';
import { TextFilterTypeDto } from '../FilterTypeDtos/text-filter-type-dto';
import { PredicateModel } from '@syncfusion/ej2-angular-grids';
import { SetFilterTypeDto } from '../FilterTypeDtos/set-filter-type-dto';

export declare type FilterModelType =
  | 'empty'
  | 'equals'
  | 'notEqual'
  | 'lessThan'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'greaterThanOrEqual'
  | 'inRange'
  | 'contains'
  | 'notContains'
  | 'startsWith'
  | 'endsWith'
  | 'blank'
  | 'notBlank';

@Injectable({
  providedIn: 'root',
})
export class FilterTranslatorService {
  constructor() {}

  getDateFilterTypeByPredicateModel(
    model: PredicateModel,
    filter: DateFilterTypeDto | undefined,
  ) {
    if (filter != undefined) {
      filter.to = model.value as Date;
      return filter;
    } else {
      return {
        from: model.value as any,
        type: model.operator
          ? this.getConditionType(
              (model.operator ?? 'equals') as FilterModelType,
            )
          : ConditionType.Equals,
      } as DateFilterTypeDto;
    }
  }
  getSetFilterTypeByPredicateModel<T>(
    model: PredicateModel,
    filter: SetFilterTypeDto<T> | undefined,
  ) {
    if (filter != undefined) {
      filter.values.push(model.value as T);
      return filter;
    } else {
      filter = { values: [model.value as number] } as SetFilterTypeDto<T>;
      if (model.operator == 'notequal') {
        filter.notEqual = true;
      }
      return filter;
    }
  }

  getTextFilterTypeByPredicateModel(model: PredicateModel) {
    return {
      filter: model.value,
      type: model.operator
        ? this.getConditionType((model.operator ?? 'equals') as FilterModelType)
        : ConditionType.Equals,
    } as TextFilterTypeDto;
  }
  getNumberFilterTypeByPredicateModel(model: PredicateModel) {
    return {
      filter: model.value,
      type: model.operator
        ? this.getConditionType((model.operator ?? 'equals') as FilterModelType)
        : ConditionType.Equals,
    } as NumberFilterTypeDto;
  }
  private getConditionType(type: FilterModelType) {
    switch (type) {
      case 'empty':
        return ConditionType.Empty;
      case 'equals':
        return ConditionType.Equals;
      case 'blank':
        return ConditionType.Blank;
      case 'notBlank':
        return ConditionType.NotBlank;
      case 'notEqual':
        return ConditionType.NotEqual;
      case 'greaterThan':
        return ConditionType.GreaterThan;
      case 'greaterThanOrEqual':
        return ConditionType.GreaterThanOrEqual;
      case 'lessThan':
        return ConditionType.LessThan;
      case 'lessThanOrEqual':
        return ConditionType.LessThanOrEqual;
      case 'inRange':
        return ConditionType.InRange;
      case 'contains':
        return ConditionType.Contains;
      case 'notContains':
        return ConditionType.NotContains;
      case 'startsWith':
        return ConditionType.StartsWith;
      case 'endsWith':
        return ConditionType.EndsWith;
    }
  }
}
