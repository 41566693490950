import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { getQueryMap } from 'ngx-common-solution';
import { tap } from 'rxjs';
import { CheckTokenService } from './stores/check-token.service';
import { LoginLogoutService } from './stores/login-logout.service';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const loginLogoutService = inject(LoginLogoutService);
  const checkTokenService = inject(CheckTokenService);
  const router = inject(Router);
  return checkTokenService.checkToken(state.url).pipe(
    tap((res) => {
      if (
        (res == false && state.url.startsWith('/?code')) ||
        state.url.startsWith('/?state')
      ) {
        const code = state.url.substring(2);
        var qmap = getQueryMap(code) ?? {};
        qmap['code'] = decodeURIComponent(qmap['code']);
        router.navigate(['code'], {
          queryParams: qmap,
        });
      }
      if (
        res == false &&
        !state.url.startsWith('/?code') &&
        !state.url.startsWith('/?state')
      )
        loginLogoutService.logout(state.url);
    }),
  );
};

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => canActivate(route, state);
