import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { SelectorConfig } from '../../selector-config';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  Observable,
  Subject,
  delay,
  of,
  startWith,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { Item } from '../../services/base-store.service';
import { SelectorComponent } from '../selector/selector.component';
import { CommonModule } from '@angular/common';
import { IfPermissionDirective } from '../directives/if-permission.directive';

export interface SelectItemEventArgs {
  item: any;
  configId?: number;
}
@Component({
  standalone: true,
  imports: [
    CommonModule,
    SelectorComponent,
    ReactiveFormsModule,
    IfPermissionDirective,
  ],
  selector: 'sol-multi-selector',
  templateUrl: './multi-selector.component.html',
  styleUrls: ['./multi-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectorComponent implements OnDestroy {
  private onDestroy$ = new Subject();
  @Input() set searchValue(searchValue: string | null) {
    of(searchValue)
      //delay, weil das FormControl sonst den Wert in valueChanges nicht ausgibt
      .pipe(take(1), delay(1))
      .subscribe((v) => this.searchControl.patchValue(searchValue));
  }
  @Input() selectorConfigs!: SelectorConfig<any, any, any>[];
  @Output() itemSelect = new EventEmitter<SelectItemEventArgs>();
  searchControl = new FormControl<string>('');
  searchValue$: Observable<string | null>;
  constructor(private cdr: ChangeDetectorRef) {
    const input$: Observable<string | null> = this.searchControl.valueChanges;
    this.searchValue$ = input$.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      // debounceTime(400),
    );
  }
  onItemSelect(item: Item, config: SelectorConfig<any, any, any>) {
    this.itemSelect.emit({ item: item, configId: config.id });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
