<ng-container *transloco="let t">
  <div class="m-2 h-full w-full rounded border border-gray-300">
    <div class="m-auto w-fit">
      <div class="mx-2 my-4 italic">
        <div>
          <ng-container *ngIf="singlePermission">
            Ihnen fehlt das Recht: {{ t("Permission." + EP[singlePermission]) }}
          </ng-container>
          <ng-container *ngIf="permissions">
            Ihnen fehlt eines dieser das Rechte:
            <ng-container *ngFor="let permission of permissions">
              - {{ t("Permission." + EP[permission]) }}
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
