<sol-loading-screen
  [isLoading]="loading$ | async"
  class="ml-3"
  *ngIf="currentUser$ | async as currentUser"
>
  <ng-container
    *ngIf="currentUser.hasUserWritePermission || (user?.id == currentUser.id)"
  >
    <div *ngIf="!apiKey && user?.apiKeyPrefix">
      <div>Aktueller API-Key</div>
      <div>{{ user?.apiKeyPrefix }}********************************</div>
    </div>
    <div *ngIf="!apiKey && !user?.apiKeyPrefix">Kein API-Key vorhanden</div>
    <div *ngIf="apiKey">
      <div>Sichern Sie diesen API-Key JETZT.</div>
      <div>
        Sobald Sie dieses Fenster schließen, können Sie den API-Key nicht
        wiederherstellen, da er nur verschlüsselt im System aufbewahrt wird.
      </div>
      <div class="py-2 text-lg font-bold">
        {{ apiKey }}
      </div>
      <div class="flex">
        <button
          ejs-button
          [cdkCopyToClipboard]="apiKey"
          (click)="success.hidden = false"
        >
          Kopieren
          <i class="fa-solid fa-clipboard text-xl"></i>
        </button>
        <div #success hidden class="mx-2 my-auto text-custom-success">
          Kopiert!
        </div>
      </div>
    </div>
    <div class="my-3 flex gap-2">
      <button
        *ngIf="apiKey || user?.apiKeyPrefix"
        (click)="onDeleteApiKey()"
        [disabled]="loading$ | async"
        ejs-button
        type="button"
        name="button"
        class="icon-button"
      >
        API-Key löschen
      </button>
      <button
        (click)="onGetNewApiKey()"
        [disabled]="loading$ | async"
        ejs-button
        type="button"
        name="button"
        class="icon-button"
      >
        API-Key neu generieren
      </button>
    </div>
  </ng-container>
</sol-loading-screen>
