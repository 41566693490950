import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorItem, GlobalErrorService } from '../../global-error.service';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ClipboardModule } from '@angular/cdk/clipboard';

@Component({
  standalone: true,
  imports: [CommonModule, ButtonModule, ClipboardModule],
  selector: 'sol-error-bar',
  templateUrl: './error-bar.component.html',
  styleUrls: ['./error-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorBarComponent {
  errors$: Observable<ErrorItem[]>;
  expanded = false;
  constructor(private globalErrorService: GlobalErrorService) {
    this.errors$ = globalErrorService.errors$;
  }
  onStacktraceClick(error: ErrorItem) {
    DialogUtility.confirm({
      title: 'Fehler',
      content: error.status + '\r' + error.title + '\r' + error.details,
    });
  }
  toggleExpanded() {
    this.expanded = !this.expanded;
  }
  dismissError(error: ErrorItem) {
    this.globalErrorService.dismissError(error);
  }
  dismissAll() {
    this.globalErrorService.dismissAll();
  }
  retryErrors() {
    this.globalErrorService.retry();
  }
}
