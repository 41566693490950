export function addDays(date: Date, days: number): Date {
  if (!days) return date;
  let newDate = date;
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function isToday(date: Date): boolean {
  let today = new Date();
  return isSameDate(date, today);
}

export function clone(date: Date): Date {
  return new Date(+date);
}

export function isAnotherMonth(date1: Date, date2: Date): boolean {
  return date1 && date2 && date1.getMonth() !== date2.getMonth();
}

export function isWeekend(date: Date): boolean {
  return date.getDay() === 0 || date.getDay() === 6;
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1 &&
    date2 &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
export function getMonday(date: Date) {
  const d = addDays(new Date(date.getTime()), 7);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  const monday = new Date(d.setDate(diff));
  return monday;
}

export function getCurrentDate() {
  var d = new Date();
  d.setHours(0, 0, 0, 0);
  return new Date(d.getTime());
}

export function isDateLaterThanNow(date: Date) {
  return new Date(date).getTime() > new Date().getTime();
}
