import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, Subject } from 'rxjs';
import { getAuthToken } from '../tokens';
import { AuthenticationStatusService } from './authentication-status.service';
import { RefreshTokenService } from './refresh-token.service';
@Injectable({
  providedIn: 'root',
})
export class CheckTokenService {
  refreshTokenInProgress = false;
  private _tokenRefreshed$ = new Subject<boolean>();
  tokenRefreshed$ = this._tokenRefreshed$.asObservable();

  constructor(
    private authenticationStatusService: AuthenticationStatusService,
    private jwtHelper: JwtHelperService,
    private refreshTokenService: RefreshTokenService,
  ) {}

  checkToken(url: string): Observable<boolean> {
    const token = getAuthToken();
    const regexCheck = new RegExp(
      '^[A-Za-z0-9-_=]+.[A-Za-z0-9-_=]+.?[A-Za-z0-9-_.+/=]*$',
    );

    if (token && regexCheck.test(token)) {
      try {
        const isValid = !this.jwtHelper.isTokenExpired(token);
        if (isValid) {
          this.authenticationStatusService.setAuthenticated(true);
          return of(true);
        }
      } catch {}
    }

    //this.logout(url);
    return this.refreshTokenService.refreshToken();
  }
}
