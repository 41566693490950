import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of, tap } from 'rxjs';

export interface ICanBlockNavigation {
  canDeactivateBlock$: Observable<boolean>;
}

export const canBlockNavigationGuard: CanDeactivateFn<ICanBlockNavigation> = (
  component: ICanBlockNavigation,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState?: RouterStateSnapshot,
): Observable<boolean> => {
  const allowedRoutes = ['/login', '/logout'];
  if (allowedRoutes.includes(nextState?.url || '')) {
    return of(true);
  }
  return component.canDeactivateBlock$;
};
