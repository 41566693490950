import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  selector: 'sol-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingScreenComponent {
  @HostBinding('class.flex') addOverlay: boolean = true;
  @Input() fullHeight = true;
  @Input() isLoading: boolean | null = false;
  @Input() opacity: number = 0.5;
  constructor() {}
}
