import { Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  shareReplay,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStatusService {
  private _isAuthenticated$ = new ReplaySubject<boolean>();
  isAuthenticated$: Observable<boolean>;
  isAuthenticated: boolean = false;
  constructor() {
    this.isAuthenticated$ = this._isAuthenticated$.pipe(
      distinctUntilChanged(),
      tap((isAuth) => (this.isAuthenticated = isAuth)),
      shareReplay(1),
    );
  }
  setAuthenticated(status: boolean) {
    this._isAuthenticated$.next(status);
  }
}
